import { useEffect, useState } from 'react';
import { NumInput } from '../components';

export { Paddy };

function Paddy(props) {
    const [values, setValues] = useState(props.data)
    const bubble = (key, val) => {
        props.callback('paddy_harvesting', {...values, [key]: val});
    };
    
    useEffect(() => {
        setValues(props.data);
    }, [props.data]);
    
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#paddy_harvesting">
                    Paddy Harvesting
                </button>
            </h2>
            <div id="paddy_harvesting" className="accordion-collapse collapse section show" data-bs-parent="#summaryForm">
                <div className="accordion-body">
                    <h6>Area under paddy (in acres)</h6>
                    <div className="row">
                        <NumInput bubble={bubble} name="area_under_paddy_basmati_acres" label="Basmati" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="area_under_paddy_non_basmati_acres" label="Non - Basmati" layout="col-lg-3 col-md-6" {...props}></NumInput>
                    </div>
                    <hr />
                    <div className="row">
                        <NumInput bubble={bubble} name="paddy_area_harvested_till_date_acres" label="Paddy area harvested till date (in acres)" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="percentage_of_harvesting_acres" label="Percentage of harvesting (In acres)" layout="col-lg-3 col-md-6" {...props}></NumInput>
                    </div>
                </div>
            </div>
        </div>
    )
}