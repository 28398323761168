import React, { useState } from 'react';
import "./table.css";

function CustomPagination(props) {
    const {page, total = 0, isLoading, numPerPage, callback} = props;

    const lastPage = total ? Math.ceil(total / numPerPage) : 1;
  
    
    const prevDisabled = page === 1;
    const nextDisabled = page === lastPage;

    function prevPage() {
        if (page <= 1) return;
        if (callback) callback(page - 1);
    }
    function nextPage() {
        if (page >= lastPage) return;
        if (callback) callback(page + 1);
    }
    
    return (
        <div className='d-flex align-items-center my-3 custom-pagination'>
            <button onClick={prevPage} className='btn btn-primary' disabled={isLoading || prevDisabled}>&#x1F878;</button>
            <div className='mx-3'>
                <span>{total === 0 ? 0 : (((page - 1) * numPerPage) + 1)} - {page === lastPage ? total : (page * numPerPage)}</span>
                <span>of</span> 
                <span>{total}</span>
            </div>
            <button onClick={nextPage} className='btn btn-primary' disabled={isLoading || nextDisabled}>&#x1F87A;</button>
        </div>
    )
}
export {CustomPagination};