import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  resetState,
  submitUsernameAsync,
} from "../redux/slice/forgotPasswordSlice";
import { useDispatch, useSelector } from "react-redux";
import ForgotPWDeatils from "./ForgotPWDeatils";
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [showError, setShowError] = useState(false);
  const [toggleComponent, setToggleComponent] = useState(true);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.forgotPasswordSlice.isAuthenticated
  );
  const status = useSelector((state) => state.forgotPasswordSlice.status);
  let errorFromRedux = useSelector(
    (state) => state?.forgotPasswordSlice?.error?.message
  );

  const message = useSelector((state) => state?.forgotPasswordSlice?.success);

  const handleNavigate = () => {
    navigate("/login");
  };

  if (message?.success) {
    toast.success(message?.success, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setTimeout((_) => {
      handleNavigate();
      dispatch(resetState());
    }, 1500);
  } else {
    toast.error(message?.success, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const handleInputChange = (e) => {
    setUserName(e.target.value);
    setShowError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userName.length) {
      setShowError(true);
      return;
    }

    await dispatch(submitUsernameAsync(userName));
    setUserName("");
  };

  return (
    <>
      {isAuthenticated && (
        <div className="container mt-5">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-6">
              <div className="card ">
                <div className="card-body">
                <h4 className="card-title text-center">Forgot Password</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            Username<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="username"
                            required
                            value={userName}
                            onChange={handleInputChange}
                            className="form-control"
                            id="username"
                            placeholder="Enter Username"
                          />
                          {showError && (
                            <div className="text-danger small">
                              Username is required.
                            </div>
                          )}
                          {errorFromRedux && (
                            <div className="text-danger small">
                              {errorFromRedux}
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-12 d-flex gap-4 justify-content-end">
                            <button
                              className="btn btn-outline-danger "
                              onClick={handleNavigate}
                              type="button"
                            >
                              Back
                            </button>
                            <button
                              className="btn btn-success "
                              disabled={status === "loading"}
                            >
                              {status === "loading"
                                ? "Sending..."
                                : "Get OTP"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isAuthenticated && <ForgotPWDeatils />}
      <ToastContainer theme="colored" closeOnClick />
    </>
  );
};

export default ForgotPassword;
