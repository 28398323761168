import { createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../apiAccess";

export const changePassword = (values) => async (dispatch) => {
  const params = {
    "username": values.username,
    "new password": values.pw,
  };
  const newData = JSON.stringify(params);

  try {
    dispatch(changePasswordRequest());
    const response = await axiosClient.patch("/api/reset_pwd/", newData);
    dispatch(changePasswordSuccess(response.data));
  } catch (error) {
    dispatch(changePasswordFailure(error.response));
  }
};

const resetPasswordSlice = createSlice({
  name: "resetPasswordSlice",
  initialState: {
    changingPassword: false,
    changeSuccess: null,
    changeError: null,
  },
  reducers: {
    changePasswordRequest: (state) => {
      state.changingPassword = true;
      state.changeSuccess = null;
      state.changeError = null;
    },
    changePasswordSuccess: (state) => {
      state.changingPassword = false;
      state.changeSuccess = true;
      state.changeError = null;
    },
    changePasswordFailure: (state, action) => {
      state.changingPassword = false;
      state.changeSuccess = false;
      state.changeError = action.payload;
    },
    changeSuccessFlag: (state, action) => {
      state.changeSuccess = action.payload;
    },
  },
});

export const {
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  changeSuccessFlag,
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
