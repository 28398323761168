import React, { useEffect, useState } from "react";
import { FilterDropdown, Navbar } from "../components/";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDistrictKPI } from "../redux/slice/kpiSlice";
import CardGroup from "../activities/CardGroup";
import filterIcon from "../assets/filter.svg";
import { Link } from "react-router-dom";
import "../activities/style.css";
import Chart from "../crmGraphs/Chart";

function Activities() {
  const dispatch = useDispatch();
  const kpi = useSelector((state) => state?.kpiSlice);

  const [districtKPI, setDistrictKPI] = useState();
  const [equipment, setEquipment] = useState("all");
  const [cropType, setCropType] = useState("all");
  const [lastUpdated, setLastUpdated] = useState(
    kpi?.data?.kpi?.last_updated?.farmer_beneffited ?? "N/A"
  );
  
  const [selectedOption, setSelectedOption] = useState("Farmer Benefitted");

  useEffect(() => {
    if (kpi && kpi.data && kpi.data.kpi && kpi.data.kpi.last_updated.farmer_beneffited) {
      setLastUpdated(kpi.data.kpi.last_updated.farmer_beneffited);
    }
  }, [kpi]);

  const handleSearch = (selected, lastUpdated) => {
    setSelectedOption(selected);
    setLastUpdated(lastUpdated);
  };

  const equipmentList = [
    { name: "Baler", value: "baler" },
    { name: "Super Seeder", value: "super_seeder" },
  ];
  const cropList = [
    { name: "Basmati", value: "basmati" },
    { name: "Non-Basmati", value: "non_basmati" },
  ];
  const cardNames = {
    total_farmers_registered: "Total Farmers Registered",
    total_land_recorded: "Total Land Recorded",
    active_equipment_suppliers: "Active Equipment Suppliers",
    farmers_with_confirmed_bookings: "Farmers with Confirmed Bookings",
    farmers_with_completed_bookings: "Farmers with Completed Bookings",
    confirmed_bookings_count: "Confirmed Bookings (in acres)",
    completed_bookings_count: "Completed Bookings (in acres)",
  };

  useEffect(() => {
    dispatch(getDistrictKPI({ equipment: equipment, crop_type: cropType }));
  }, [equipment, cropType]);

  useEffect(() => {
    setDistrictKPI(kpi?.data);
  }, [kpi]);


  return (
    <>
      <Navbar active="activities" />
      <div className="container mt-5">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            Last Updated: <strong>{lastUpdated}</strong>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <span>Filter By</span>
              <img
                className="ms-1 me-2"
                src={filterIcon}
                alt="filter"
                style={{ height: "1.25em" }}
              />
            </div>
            <FilterDropdown
              callback={(e) => setEquipment(e)}
              defaultSelection="All Equipment"
              data={equipmentList}
              autoWidth
            />
            <FilterDropdown
              callback={(e) => setCropType(e)}
              defaultSelection="All Crop Types"
              data={cropList}
              autoWidth
            />
          </div>
        </div>
        <div id="cards">
          <CardGroup cardNames={cardNames} data={districtKPI} />
        </div>
        <Chart onSelect={handleSearch}/>
      </div>
    </>
  );
}
export default Activities;
