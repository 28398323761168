import { useState, useEffect } from 'react';
import { NumInput } from '../components';

export { AreaCovered };

function AreaCovered(props) {
    const [values, setValues] = useState(props.data)
    const [totals, setTotals] = useState({
        "chc.grand_total": false,
        "individual.grand_total": false
    });
    const bubble = (key, val) => {
        const [parent, child] = key.split('.');
        var parentObj = {...values[parent], [child]: val}
        if (Object.keys(totals).includes(key)) {
            setTotals({...totals, [key]: true});
        } else {
            parentObj = handleTotals(parentObj, parent);
        }
        props.callback('area_covered_crm_machinery', {...values, [parent]: parentObj});
    };

    function handleTotals(nextValues, parentKey) {
        const [totalKey, totalValue] = Object.entries(totals).find(([name]) => name.indexOf(parentKey) === 0);
        const [totalParent, totalChild] = totalKey.split('.');
        if (totalValue) return nextValues;
        let sum = 0;
        Object.entries(nextValues)
            .filter(([name, val]) => name !== totalChild && !isNaN(parseFloat(val)))
            .forEach(([name, val]) => sum += parseFloat(val));
        nextValues[totalChild] = `${sum}`;
        return nextValues;
    }
    
    useEffect(() => {
        setValues(props.data);
    }, [props.data]);

    const flatten = obj => {
        var o = {};
        for (var i in obj) {
            if (!obj.hasOwnProperty(i)) continue;
            if ((typeof obj[i]) == 'object' && obj[i] !== null) {
                var flatO = flatten(obj[i]);
                for (var x in flatO) {
                    if (!flatO.hasOwnProperty(x)) continue;
                    o[`${i}.${x}`] = flatO[x];
                }
            } else {
                o[i] = obj[i];
            }
        }
        return o;
    }
    const flatProps = Object.assign({}, props, {data: flatten(props.data)});
    
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#area_covered_crm_machinery">
                Area Covered in Acres by CRM machinery available with CHC and Individual Component during current kharif season 2022-23
                </button>
            </h2>
            <div id="area_covered_crm_machinery" className="accordion-collapse collapse section" data-bs-parent="#summaryForm">
                <div className="accordion-body">
                    <div className="row">
                        <div className="col-md-6">
                            <h6>Straw Baler Units with Rake and slasher</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="chc.straw_baler_units_with_rake_slasher" label="CHC" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="individual.straw_baler_units_with_rake_slasher" label="Individual" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Super SMS/ Slasher/ chopper/ Mulcher/ crop reaper with  Super Seeder</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="chc.super_with_super_seeder" label="CHC" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="individual.super_with_super_seeder" label="Individual" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Super SMS/ Slasher/ chopper/ Mulcher/ crop reaper with Reversible M. B Plough</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="chc.super_with_reversible_plough" label="CHC" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="individual.super_with_reversible_plough" label="Individual" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Super SMS/ Slasher/ chopper/ Mulcher/ crop reaper with Happy Seeder</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="chc.super_with_happy_seeder" label="CHC" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="individual.super_with_happy_seeder" label="Individual" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Super SMS/ Slasher/ chopper/ Mulcher/ crop reaper with Zero tillage</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="chc.super_with_zero_tillage" label="CHC" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="individual.super_with_zero_tillage" label="Individual" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Total Area Covered in Acres</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="chc.total_area_covered" label="CHC" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="individual.total_area_covered" label="Individual" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Grand Total</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="chc.grand_total" label="CHC" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="individual.grand_total" label="Individual" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}