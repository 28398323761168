import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  changeSuccessFlag,
} from "../redux/slice/resetPasswordSlice";
import { Navbar } from "../components/";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { logout } from "../redux/slice/authSlice";
import storage from "redux-persist/lib/storage";
import { clearStoreDataAction } from "../redux/slice/getUserSlice";

function ChangePasswordPage(props) {
  const user = JSON.parse(localStorage.getItem("userData"));
  const status = useSelector((state) => state?.resetPasswordSlice);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pw, setPw] = useState("");
  const [pwConfirm, setPwConfirm] = useState("");

  useEffect(() => {
    if (status.changeSuccess) {
      toast.success("Password Change Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout((_) => {
        dispatch(logout());
        storage.setItem("persist:root", null);
        dispatch(clearStoreDataAction());
        ['authToken', 'userData'].forEach(key => localStorage.removeItem(key));
        navigate("/login");
      }, 1500);
      dispatch(changeSuccessFlag(null));
    }
  }, [status.changeSuccess]);

  const updatePwConfirm = (e) => {
    setPwConfirm(e.target.value);
    if (pw !== e.target.value) {
      setError("Passwords dont match");
    } else {
      setError("");
    }
  };

  const updatePw = (e) => {
    setPw(e.target.value);
    if (pwConfirm === e.target.value) {
      setError("");
    } 
    // else {
    //   setError("Passwords dont match");
    // }
  };

  function formSubmit(e) {
    e.preventDefault();
    dispatch(
      changePassword({
        username: user?.username,
        pw: pw,
      })
    );
  }

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row">
          <h3 className="mb-4 text-center">Change Password</h3>
          <form onSubmit={formSubmit} className="container col-md-6">
            <div className="mb-4">
              <label htmlFor="username" className="form-label mb-1">
                Username
              </label>
              <input
                type="text"
                value={user?.username}
                disabled
                className="form-control"
                name="username"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="pw" className="form-label mb-1">
                New Password
              </label>
              <input
                type="password"
                value={pw}
                onChange={(e) => updatePw(e)}
                className="form-control"
                name="pw"
                placeholder="New password"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="pw_confirm" className="form-label mb-1">
                Confirm New Password
              </label>
              <input
                type="password"
                value={pwConfirm}
                onChange={(e) => updatePwConfirm(e)}
                className="form-control"
                name="pw_confirm"
                placeholder="Confirm New password"
                required
              />
            </div>
            <div className="error text-danger mt-3">{error}</div>
            <div className={"d-flex justify-content-end pt-4"}>
              <button
                type="submit"
                className="btn btn-success"
                disabled={pw !== pwConfirm}
              >
                Change Password
              </button>
            </div>
            <ToastContainer />
          </form>
        </div>
      </div>
    </>
  );
}

export default ChangePasswordPage;
