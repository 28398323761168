import React from "react";
import { useNavigate } from "react-router-dom";
import storage from "redux-persist/lib/storage";
import { clearStoreDataAction } from "../redux/slice/getUserSlice";
import { logout } from "../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
function Navbar(props) {
  const { active } = props;
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let isLoggedIn = localStorage.getItem("authToken");
  let userData = JSON.parse(localStorage.getItem("userData"));

  const handleLogOut = () => {
    dispatch(logout());
    storage.setItem("persist:root", null);
    dispatch(clearStoreDataAction());
    ["authToken", "userData"].forEach((key) => localStorage.removeItem(key));
    navigate("/login");
  };
  return (
    <>
      <nav className="navbar sticky-top bg-white shadow-sm navbar-expand-md p-0">
        <div className="container-fluid">
          <div
            className="navbar-brand text-project pointer"
            onClick={() => navigate(`/${isLoggedIn ? "" : "login"}`)}
          >
            <strong>CRM</strong> Haryana Portal
          </div>
          <button
            className="navbar-toggler my-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#nav"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="nav"
          >
            <ul className="navbar-nav ">
              {isLoggedIn ? (
                <li
                  className={`nav-item px-2 pointer ${
                    active === "overview" ? "active" : ""
                  }`}
                >
                  <div
                    className="nav-link"
                    onClick={() => navigate("/")}
                  >
                    Overview
                  </div>
                </li>
              ) : (
                ""
              )}

                <li
                    className={`nav-item px-2 pointer ${
                      active === "events" ? "active" : ""
                    }`}
                  >
                    <div
                      className="nav-link"
                      onClick={() => navigate("/eventlandingpage")}
                    >
                      Events
                    </div>
                  </li>
              <li
                className={`nav-item px-2 pointer ${
                  active === "marketplace" ? "active" : ""
                }`}
              >
                <div
                  className="nav-link"
                  onClick={() => navigate("/marketplace")}
                >
                  Marketplace
                </div>
              </li>
              <li
                className={`nav-item px-2 pointer ${
                  active === "activities" ? "active" : ""
                }`}
              >
                <div
                  className="nav-link"
                  onClick={() => navigate("/activities")}
                >
                  CRM Activities
                </div>
              </li>
              {isLoggedIn ? (
                <li
                  className={`nav-item px-2 pointer ${
                    active === "database" ? "active" : ""
                  }`}
                >
                  <div
                    className="nav-link"
                    onClick={() => navigate("/database")}
                  >
                    Database
                  </div>
                </li>
              ) : (
                ""
              )}
              {isLoggedIn ? (
                <li className="nav-item px-3 pointer dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    {userData?.name}
                  </div>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <div
                        onClick={() => navigate("/change_password")}
                        className="dropdown-item"
                      >
                        Change Password
                      </div>
                    </li>
                    <li>
                      <div onClick={handleLogOut} className="dropdown-item">
                        Logout
                      </div>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item px-3 pointer">
                  <div className="nav-link" onClick={() => navigate("/login")}>
                    Login
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export { Navbar };
