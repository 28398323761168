import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../apiAccess";

export const getMarketSource = createAsyncThunk('marketSlice', async (sourceName, thunkAPI) => {
  try {
    const response = await axiosClient.get(`/annexure/marketplace/?source=${sourceName}`);
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getMarketCounts = createAsyncThunk('marketCount', async (thunkAPI) => {
  try {
    const response = await axiosClient.get("/annexure/marketplace_count/");
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getFilteredBlockList = createAsyncThunk('getBlocks', async (district_id, thunkAPI) => {
  if (!district_id) return;
  try {
    const response = await axiosClient.post("/annexure/getBlocks/", {district_id});
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getFilteredVillageList = createAsyncThunk('getVillages', async (block_id, thunkAPI) => {
  if (!block_id) return;
  try {
    const response = await axiosClient.post("/annexure/getVillages/", {block_id});
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const marketSlice = createSlice({
    name: 'marketSlice',
    initialState: {
      loading: false,
      error: null,
      data: null,
      marketCounts: {},
      blockList: [],
      loadingBlocks: false,
      villageList: [],
      loadingVillages: false,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getMarketSource.pending, (state) => {
          state.loading = true;
        })
        .addCase(getMarketSource.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getMarketSource.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(getMarketCounts.pending, (state) => {
          return { ...state, countLoading: true }
        })
        .addCase(getMarketCounts.fulfilled, (state, action) => {
          return { ...state, countLoading: false, marketCounts: action?.payload }
        })
        .addCase(getMarketCounts.rejected, (state, action) => {
          return { ...state, countLoading: false }
        })
        .addCase(getFilteredBlockList.pending, (state) => {
          return { ...state, loadingBlocks: true }
        })
        .addCase(getFilteredBlockList.fulfilled, (state, action) => {
          return { ...state, loadingBlocks: false, blockList: action?.payload }
        })
        .addCase(getFilteredBlockList.rejected, (state, action) => {
          return { ...state, loadingBlocks: false, blockList: [] }
        })
        .addCase(getFilteredVillageList.pending, (state) => {
          return { ...state, loadingVillages: true }
        })
        .addCase(getFilteredVillageList.fulfilled, (state, action) => {
          return { ...state, loadingVillages: false, villageList: action?.payload }
        })
        .addCase(getFilteredVillageList.rejected, (state, action) => {
          return { ...state, loadingVillages: false, villageList: [] }
        })
        ;
    },
  });
  
  export default marketSlice.reducer;