export const data = {
	"paddy_harvesting": {
		"area_under_paddy_basmati_acres": 0,
		"area_under_paddy_non_basmati_acres": 0,
		"paddy_area_harvested_till_date_acres": 0,
		"percentage_of_harvesting_acres": 0
	},
	"decomposer": {
		"decomposer_sprayed_by_dept": 0,
		"kits_delivered_farmer": 0,
		"sprayed_on_farmer_field": 0,
		"registration_on_portal": 0,
		"farmers_benifitted": 0
	},
	"dsr_enforcement_measures": {
		"afl_harsac": 0,
		"afl_other": 0,
		"total_afl": 0,
		"locations_no_fire_incidents": 0,
		"locations_fire_incidents": 0,
		"afl_non_agri_land": 0,
		"afl_agri_land": 0,
		"fir_lodged_dept": 0,
		"challan_imposed": 0,
		"penalty_imposed": 0,
		"fine_collected": 0,
		"use_fire_tendor": 0
	},
	"achievement_iec_activities_under_crm": {
		"physical": {
			"awareness_programs_conducted_village_level": 0,
			"awareness_programs_conducted_panchayat_level": 0,
			"awareness_programs_conducted_block_level": 0,
			"awareness_programs_conducted_district_level": 0,
			"training_programs_conducted": 0,
			"no_of_farmers_trained": 0,
			"kisan_melas_organized": 0,
			"demonstration_conducted": 0,
			"exposure_visits_organized": 0,
			"field_days_organized": 0,
			"advertisement_in_print_media": 0,
            "hoarding_fixed": 0,
            "publicity_material": 0,
            "tv_programmes": 0,
            "wall_painting": 0,
            "demonstration_van": 0,
			"school_college_level_activities": 0,
			"no_of_activities": 0,
            "no_of_student_participate": 0,
			"total_financial_expenditure": 0
		},
		"finanacial": {
			"awareness_programs_conducted_village_level": 0,
			"awareness_programs_conducted_panchayat_level": 0,
			"awareness_programs_conducted_block_level": 0,
			"awareness_programs_conducted_district_level": 0,
			"training_programs_conducted": 0,
			"no_of_farmers_trained": 0,
			"kisan_melas_organized": 0,
			"demonstration_conducted": 0,
			"exposure_visits_organized": 0,
			"field_days_organized": 0,
			"advertisement_in_print_media": 0,
            "hoarding_fixed": 0,
            "publicity_material": 0,
            "tv_programmes": 0,
            "wall_painting": 0,
            "demonstration_van": 0,
			"school_college_level_activities": 0,
			"no_of_activities": 0,
            "no_of_student_participate": 0,
			"total_financial_expenditure": 0
		}
	},
	"area_covered_crm_machinery": {
		"chc": {
			"straw_baler_units_with_rake_slasher": 0,
			"super_with_super_seeder": 0,
			"super_with_reversible_plough": 0,
			"super_with_happy_seeder": 0,
			"super_with_zero_tillage": 0,
			"total_area_covered": 0,
			"grand_total": 0
		},
		"individual": {
			"straw_baler_units_with_rake_slasher": 0,
			"super_with_super_seeder": 0,
			"super_with_reversible_plough": 0,
			"super_with_happy_seeder": 0,
			"super_with_zero_tillage": 0,
			"total_area_covered": 0,
			"grand_total": 0
		}
	},
	"progress_regarding_incentive_insitu_exsitu":{
		"details_uploaded_on_portal": {
			"no_of_farmers": 0,
			"area": 0,
			"incentive_amount": 0
		},
		"approved_by_dlec_till_date": {
			"no_of_farmers": 0,
			"area": 0,
			"incentive_amount": 0
		},
		"total_expected_area_managed_details": {
			"no_of_farmers": 0,
			"area": 0,
			"incentive_amount": 0
		},
		"incentive_to_gaushalas": {
			"no_of_gaushalas": 0,
			"paddy_Straw_consumed": 0,
			"incentive": 0
		},
		"award_to_panchayats": {
			"from_red_to_green_zone": 0,
			"from_green_to_yellow_zone": 0,
		}
	},
	"utilization_in_industries": {
		"tentative_straw_generation_tonnes": 0,
		"industries_vicinity_district_utilizing_paddy_straw_1": 0,
		"industries_vicinity_district_utilizing_paddy_straw_2": 0,
		"paddy_straw_produced_by_industries_tonnes": 0
	},
	"water_conservation_techniques": {
		"chc": {
			"laser_land_leveller": 0,
			"other": 0,
			"grand_total": 0
		},
		"individual": {
			"laser_land_leveller": 0,
			"other": 0,
			"grand_total": 0
		}
	}
}