const cleanUp = (row, index) => {
    if (!row || !row.length) return row;
    if (/^[a-zA-Z]{3}$/.test(row)) return row.toUpperCase();
    return row.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const market_columns = {
    bale_demand: [
        {
            name: 'Organisation Name',
            selector: row => row.organization_name,
        },
        {
            name: 'Representative',
            selector: row => row.Representative,
            format: row => cleanUp(row.Representative)
        },
        {
            name: 'Type of Owner',
            selector: row => row.reference,
            format: row => cleanUp(row.reference)
        },
        {
            name: 'Bale Type',
            selector: row => row.Crop_Type,
        },
        {
            name: 'Annual Demand (Quintals)',
            selector: row => row.Annual_Requirement,
            width: '100px',
        },
        {
            name: 'Current Demand (Quintals)',
            selector: row => row.Current_Requirement,
            width: '100px',
        },
        {
            name: 'End Product',
            selector: row => row.End_Product,
        },
        {
            name: 'District',
            selector: row => row.district_name,
            format: row => cleanUp(row.district_name)
        },
        {
            name: 'Block',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name)
        },
        {
            name: 'Village',
            selector: row => row.village_name,
        },
    ],
    bale_supply: [
        {
            name: 'Organisation Name',
            selector: row => row.organization_name,
        },
        {
            name: 'Representative',
            selector: row => row.Representative,
            format: row => cleanUp(row.Representative)
        },
        {
            name: 'Type of Owner',
            selector: row => row.reference,
            format: row => cleanUp(row.reference)
        },
        {
            name: 'Bale Type',
            selector: row => row.Bale_Type,
        },
        {
            name: 'Total Collected (Quintals)',
            selector: row => row.Total_Bale_Collected,
            width: '120px'
        },
        {
            name: 'Available For Sale (Quintals)',
            selector: row => row.bales_available_for_sale,
            width: '120px'
        },
        {
            name: 'District',
            selector: row => row.district_name,
            format: row => cleanUp(row.district_name)
        },
        {
            name: 'Block',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name)
        },
        {
            name: 'Village',
            selector: row => row.village_name,
            format: row => cleanUp(row.village_name)
        },
    ],
    equipment: [
        {
            name: 'Organisation Name',
            selector: row => row.organization_name,
        },
        {
            name: 'Representative',
            selector: row => row.Representative,
            format: row => cleanUp(row.Representative)
        },
        {
            name: 'Type of Owner',
            selector: row => row.reference,
            format: row => cleanUp(row.reference)
        },
        {
            name: 'CRM Solutions',
            selector: row => row.chc_equipment_name,
            format: row => cleanUp(row.chc_equipment_name)
        },
        {
            name: 'District',
            selector: row => row.district_name,
            format: row => cleanUp(row.district_name)
        },
        {
            name: 'Block',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name)
        },
        {
            name: 'Village',
            selector: row => row.village_name,
            format: row => cleanUp(row.village_name)
        },
    ],
    storage: [
        {
            name: 'Organisation Name',
            selector: row => row.organization_name,
        },
        {
            name: 'Representative',
            selector: row => row.Representative,
            format: row => cleanUp(row.Representative)
        },
        {
            name: 'Type of Owner',
            selector: row => row.reference,
            format: row => cleanUp(row.reference)
        },
        {
            name: 'Storage Name',
            selector: row => row.chc_storage_name,
        },
        {
            name: 'Storage Type',
            selector: row => row.chc_storage_type,
        },
        {
            name: 'Total Capacity (Acres)',
            selector: row => row.chc_storage_capacity_total,
        },
        {
            name: 'Available Capacity (Acres)',
            selector: row => row.chc_available_capacity,
        },
        {
            name: 'District',
            selector: row => row.district_name,
            format: row => cleanUp(row.district_name)
        },
        {
            name: 'Block',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name)
        },
        {
            name: 'Village',
            selector: row => row.village_name,
            format: row => cleanUp(row.village_name)
        },
    ]
};
export {market_columns};