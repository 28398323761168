import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';


import "./index.css";

// import { store } from "redux/store";
import store, { persistor } from "../src/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
      <BrowserRouter>
      <PersistGate persistor={persistor}></PersistGate>
        <App/>
      </BrowserRouter>
    </Provider>
);
