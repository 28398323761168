import { createSlice } from "@reduxjs/toolkit";

import axiosClient from "../../apiAccess";

export const login = (credentials) => async (dispatch) => {
  const newData = JSON.stringify(credentials);
  try {
    dispatch(loginStart());
    const response = await axiosClient.post("/api/login/", newData);
    if (response?.data?.token) {
      dispatch(loginSuccess(response.data));
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('userData', JSON.stringify(response.data));
    } else {
      dispatch(loginFailure(response?.data));

    }
  } catch (error) { 
    dispatch(loginFailure(error?.response?.data));
  }
};

const initialState = {
  user: null,
  isLoading: false,
  error: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
     
    },
  },
});

export const { loginStart, loginSuccess, loginFailure,logout } = authSlice.actions;
export default authSlice.reducer;
