import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { summaryReportPost, summaryReportPut } from '../redux/slice/summaryReportSlice';
import { useNavigate } from "react-router-dom";

import { Paddy, Decomposer, DailyStatus, Achievement, AreaCovered, Progress, Utilisation, Water } from './';
import {isInvalid} from './functions.js';

export { DistrictData };

function DistrictData(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const {district_id, readOnly, data, date, saveDraft, section_ids} = props;
    const user = JSON.parse(localStorage.getItem("userData"));
    const [values, setValues] = useState({});
    const [isReviewing, setIsReviewing] = useState(false);
    const [hasEdited, setHasEdited] = useState(false);
    const submitLoading = useSelector((state) => state?.summaryReportSlice?.isLoading);
    const submitFail = useSelector((state) => state?.summaryReportSlice?.error);
    
    const updateValues = (key, val) => {
        const next = {...values, [key]: val};
        setValues(next);
        if (saveDraft){
            if (!isInvalid(next)) {
                localStorage.setItem('summary_draft', JSON.stringify(next));
            } else {
                localStorage.removeItem('summary_draft');
            }
        } 
        setHasEdited(true);
    };

    useEffect(() => {
        setValues(data);
    }, [data]);

    // modify payload to convert all add `district` and `user_id` to innermost child of each section.
    function modifyPayload(obj, id) {
        if (typeof obj === 'object' && obj !== null) {
            for (const prop in obj) {
                if (typeof obj[prop] === 'object' && obj[prop] !== null) {
                    modifyPayload(obj[prop], id);
                } else {
                    obj.district = id;
                    obj.user_id = user.id;
                }
                if (typeof obj[prop] === 'string') { 
                    obj[prop] = isNaN(parseFloat(obj[prop])) ? null : parseFloat(obj[prop]);
                }
            }
        }
    }

    function addSectionIds(obj) {
        if (!section_ids || !Object.keys(obj).length) return obj;
        Object.entries(section_ids).forEach(([section, value]) => {
            if (typeof value === 'number') {
                obj[section].id = value;
            } else if (typeof value === 'object') {
                Object.entries(value).forEach(([subsection, subvalue]) => {
                    obj[section][subsection].id = subvalue;
                })
            }
        })
    }

    function renameKey(obj, oldKey, newKey) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) obj[key] = renameKey(obj[key], oldKey, newKey);
                if (key === oldKey) {
                    obj[newKey] = obj[key];
                    delete obj[key];
                }
            }
        }
        return obj;
    }

    function formatData(e) {
        e.preventDefault();
        let payload = values;
        setHasEdited(false);
        modifyPayload(payload, district_id);
        addSectionIds(payload);
        renameKey(payload, 'finanacial', 'financial');
        payload.saving_for_date = date;
        dispatch(section_ids ? summaryReportPut(payload, navigate) : summaryReportPost(payload, navigate));
    }
    function toggleReview() {
        setIsReviewing(!isReviewing);
        setHasEdited(true);
    }

    return (
        <div id="summaryForm" className="mb-5" noValidate>
            <div className="accordion">
                <Paddy readOnly={isReviewing || readOnly} callback={updateValues} data={values?.paddy_harvesting} />
                <Decomposer readOnly={isReviewing || readOnly} callback={updateValues} data={values?.decomposer} />
                <DailyStatus readOnly={isReviewing || readOnly} callback={updateValues} data={values?.dsr_enforcement_measures} />
                <Achievement readOnly={isReviewing || readOnly} callback={updateValues} data={values?.achievement_iec_activities_under_crm} />
                <AreaCovered readOnly={isReviewing || readOnly} callback={updateValues} data={values?.area_covered_crm_machinery} />
                <Progress readOnly={isReviewing || readOnly} callback={updateValues} data={values?.progress_regarding_incentive_insitu_exsitu} />
                <Utilisation readOnly={isReviewing || readOnly} callback={updateValues} data={values?.utilization_in_industries} />
                <Water readOnly={isReviewing || readOnly} callback={updateValues} data={values?.water_conservation_techniques} />
            </div>
            {readOnly ? '' : (<div className="d-flex justify-content-between my-4">
                <div>
                    <button 
                        onClick={toggleReview} 
                        className={`btn btn-${isReviewing ? 'outline-secondary px-5' : 'success px-4'} ${submitLoading ? 'disabled' : ''}`}
                    >
                        {isReviewing ? 'Edit' : 'Review'}
                    </button>
                    <button 
                        onClick={formatData}
                        className={`btn btn-success px-4 ms-3 ${!isReviewing ? 'd-none' : ''} ${submitLoading ? 'disabled' : ''}`}
                    >
                        {submitLoading ? 'Submitting' : 'Submit Form'}
                    </button>
                </div>
                {submitFail && !hasEdited? 
                    <div className='errors'>
                        <span className="text-danger mr-4">
                            <strong>There was a problem submitting the form.</strong>
                        </span>
                    </div> : ''
                }
            </div>)}
        </div>
    )
}