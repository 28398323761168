import { useState } from "react";

function TabGroup(props) {
    const {tabData, callback, active} = props;
    const [activeTab, setActive] = useState(active);

    function handleChange(tab) {
        if (activeTab === tab.name) return;
        setActive(tab.name);
        callback(tab);
    }

    return (
        <div className="tab-group d-flex mt-4">
            {tabData.map((t, i) => (
                <div id={t.name} key={i} onClick={() => handleChange(t)} className={`tab p-2 me-3 ${t.name === activeTab ? 'active' : ''}`}>
                    {t.label} {t.amount !== undefined ? <span>({t.amount})</span> : ''}
                </div>
            ))}
        </div>
    )
}

export {TabGroup}