import { createSlice } from '@reduxjs/toolkit';
import axiosClient from '../../apiAccess';

export const getDistrictKPI = (payload) => async (dispatch) => {
  const {crop_type, equipment} = payload;
  dispatch(pending());
  try {
    const response = await axiosClient.get('/annexure/kpi/', {params: {crop_type, equipment}});
    dispatch(fulfilled(response));
  } catch (e) {
    dispatch(rejected(e.response));
    console.error('Error fetching District KPI Data', e);
  }
}

export const getVillageKPI = (payload) => async (dispatch) => {
  const {district, block, village, crop_type, page} = payload;
  dispatch(pending());
  try {
    const response = await axiosClient.get('/annexure/village_kpi/', {params: {
      crop_type, 
      page, 
      district_id: district,
      block_id: block,
      village_code: village
    }});
    dispatch(fulfilled(response));
  } catch (e) {
    dispatch(rejected(e.response));
    console.error('Error fetching Village KPI Data', e);
  }
}

const kpiSlice = createSlice({
  name: 'kpi',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    pending: (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    },
    fulfilled: (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
    },
    rejected: (state, action) => {
      state.loading = false;
      state.error = action?.error?.message
    }
  }
});
export const {pending, fulfilled, rejected} = kpiSlice.actions;

export default kpiSlice.reducer;
