// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crm_heading {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.hr_line {
  width: 60%;
  border-bottom: 1px solid #a7a7a7;
}
.h1_typography {
  color: #585858;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.h1_typography span {
  color: #667085;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.recharts-legend-wrapper {
  position: relative;
}
.recharts-default-legend {
  position: absolute;
  left: 20% !important;
  bottom: 302px !important;
}
`, "",{"version":3,"sources":["webpack://./src/crmGraphs/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,gCAAgC;AAClC;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,wBAAwB;AAC1B","sourcesContent":[".crm_heading {\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.hr_line {\n  width: 60%;\n  border-bottom: 1px solid #a7a7a7;\n}\n.h1_typography {\n  color: #585858;\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n.h1_typography span {\n  color: #667085;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 150%;\n}\n.recharts-legend-wrapper {\n  position: relative;\n}\n.recharts-default-legend {\n  position: absolute;\n  left: 20% !important;\n  bottom: 302px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
