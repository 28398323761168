import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from '../redux/slice/authSlice';
import summaryReportSlice from '../redux/slice/summaryReportSlice';
import getUserSlice from '../redux/slice/getUserSlice';
import marketSlice from '../redux/slice/marketSlice';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import resetPasswordSlice from './slice/resetPasswordSlice';
import dataBaseSlice from './slice/dataBaseSlice';
import getEventSlice from './slice/getEventSlice';
import createEventSlice from './slice/createEventSlice';
import forgotPasswordSlice from './slice/forgotPasswordSlice';
import kpiSlice from './slice/kpiSlice';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const reducer = combineReducers({
  auth: authReducer,
  summaryReportSlice: summaryReportSlice,
  getUserSlice: getUserSlice,
  resetPasswordSlice:resetPasswordSlice,
  marketSlice: marketSlice,
  dataBaseSlice: dataBaseSlice,
  getEventSlice: getEventSlice,
  createEventSlice: createEventSlice,
  forgotPasswordSlice: forgotPasswordSlice,
  kpiSlice: kpiSlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer, 
  
});

export const persistor = persistStore(store);

export default  store; 
