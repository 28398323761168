const cleanUp = (row, index) => {
    if (!row || !row.length) return row;
    return row.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
const Equipment = (props) => {
    const {name = '', count = 0} = props
    // const className = name && name.length && typeof name === 'string' ? name.toLowerCase().split(' ').join('-') : name;
    return (
        <>
            {
                (count && !isNaN(parseInt(count)) && parseInt(count) > 0) ? 
                    // <span className={`equipment ${className}`}>{name} - {count}</span> :
                    <span className="">{name}</span> :
                    <></>
            }
        </>
    )
}

const dataBase_columns = {
    farmer_db: [
        {
            name: 'Farmer Name',
            selector: row => row.farmer_name,
            format: row => cleanUp(row.farmer_name)
        },
       
        {
            name: 'Father\'s Name',
            selector: row => row.father_name,
            format: row => cleanUp(row.father_name)
        },
        {
            name: 'Total Area (Acres)',
            selector: row => row.total_farm_area,
            format: row => {
                var total = row.total_farm_area;
                if (!total || isNaN(parseFloat(total))) return total;
                return parseFloat(total).toFixed(2);
            },
            width: '120px'
        },
        {
            name: 'Crop Type',
            selector: row => row.crop_type,
            format: row => {
                if (!row.crop_type || !row.crop_type.length || typeof row.crop_type !== 'string') return ''
                if (row.crop_type.indexOf(',') === -1) return row.crop_type.replace(' variety', '');
                return row.crop_type.split(',').map(s => s.replace(' variety', '')).join(', ');
            }
        },
        // {
        //     name: 'No Of Plots',
        //     selector: row => row.no_of_plots,
        //     width: '100px',
        // },
        {
            name: 'CRM Solutions',
            cell: (row) => (
                <div className="data-column">
                  <Equipment name="Tractor (<55hp)" count={row['no_of_tractor(<55hp)']} />
                  <Equipment name="Tractor (>55hp)" count={row['no_of_tractor(>55hp)']} />
                  <Equipment name="Baler" count={row['no_of_baler']} />
                  <Equipment name="Slasher" count={row['no_of_cutter/slasher']} />
                  <Equipment name="Seeder" count={row['no_of_happy_seeder']} />
                  <Equipment name="Hay Rake" count={row['no_of_hayrake']} />
                  <Equipment name="Lucky Seeder" count={row['no_of_luckyseeder']} />
                  <Equipment name="Rotary Slasher" count={row['no_of_rotary_slasher']} />
                  <Equipment name="Super Seeder" count={row['no_of_superseeder']} />
                </div>
            ),
            width: '300px',
        },
        {
            name: 'Village',
            selector: row => row.village_name,
            width: '120px',
        },
        {
            name: 'Block',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name),
            width: '120px',
        },
        {
            name: 'District',
            selector: row => row.district_name,
            format: row => cleanUp(row.district_name),
            width: '120px',
        },
    ],
    chc_db: [
        {
            name: 'Entity Name',
            selector: row => row.Entity_Name,
            fomrat: row => cleanUp(row.Entity_Name)
        },
        {
            name: 'Representative Name',
            selector: row => row.Representative_Name,
            fomrat: row => cleanUp(row.Representative_Name)
        },
        //Commenting till its fixed by BE
        // {
        //     name: 'Contact No.',
        //     selector: row => row.Representative_Mob_No,
        //     format: row => cleanUp(row.Representative_Mob_No),
        //     width: '120px'
        // },
        {
            name: 'CHC Location',
            selector: row => row.block_name,
            format: row => `${row.block_name} | ${row.village_name}`
        },
        {
            name: 'CRM Solutions',
            cell: (row) => (
                <div className="data-column">
                    <Equipment name="Tractor" count={row['no_of_tractor']} />
                    <Equipment name="Cutter" count={row['no_of_cutter/chopper']} />
                    <Equipment name="Slasher" count={row['no_of_cutter/slasher']} />
                    <Equipment name="Seeder" count={row['no_of_happy_seeder']} />
                    <Equipment name="Hay Rake" count={row['no_of_hay_rake']} />
                    <Equipment name="Lucky Seeder" count={row['no_of_luckysedder']} />
                    <Equipment name="Rotary Slasher" count={row['no_of_rotary_slasher']} />
                    <Equipment name="Super Seeder" count={row['no_of_supersedder']} />
                </div>
            ),
            width: '250px'
        },
        {
            name: 'Storage Details',
            cell: (row) => (
                <div className="data-column">
                    Open: {row['storage_open'] || 0} |
                    Roofed: {row['storage_roofed'] || 0}
                </div>
            ),
            width: '150px'
        },
        {
            name: 'Total Storage Area (Acres)',
            selector: row => row.chc_storage_capacity_total,
            format: row => cleanUp(row.chc_storage_capacity_total),
            width: '120px'
        },
        {
            name: 'Village',
            selector: row => row.village_name,
            format: row => cleanUp(row.village_name),
            width: '120px',
        },
        {
            name: 'Block',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name),
            width: '120px',
        },
        {
            name: 'District',
            selector: row => row.district_name,
            format: row => cleanUp(row.district_name),
            width: '120px',
        },
    ],
    industry_db: [
        {
            name: 'Entity Name',
            selector: row => row.Industry_End_Product,
            format: row => cleanUp(row.Industry_End_Product)
        },
        {
            name: 'Representative Name',
            selector: row => row.Industry_Representative_Name,
        },
        {
            name: 'Contact No.',
            selector: row => row.Representative_Mob_No,
            format: row => cleanUp(row.Representative_Mob_No)
        },
        {
            name: 'Industry Type',
            selector: row => row.Industry_Type,
            format: row => cleanUp(row.Industry_Type)
        },
        {
            name: 'End Product',
            selector: row => row.Industry_End_Product,
            format: row => cleanUp(row.Industry_End_Product)
        },
        {
            name: 'Storage Details',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name)
        },
        {
            name: 'Storage Area(Tonnes)',
            selector: row => row.chc_storage_capacity_total,
            format: row => cleanUp(row.chc_storage_capacity_total)
        },
        {
            name: 'District',
            selector: row => row.district_name,
            format: row => cleanUp(row.district_name)
        },
        {
            name: 'Village',
            selector: row => row.village_name,
            format: row => cleanUp(row.village_name)
        },
        {
            name: 'Block',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name)
        }
    ],
    third_party_db: [
        {
            name: 'Entity Name',
            selector: row => row.organization_name,
        },
        {
            name: 'Representative Name',
            selector: row => row.third_party_representative_name,
        },
        {
            name: 'Contact No.',
            selector: row => row.third_party_representative_mobile_number,
            format: row => cleanUp(row.third_party_representative_mobile_number)
        },
        {
            name: 'Third Party Type',
            selector: row => row.third_party_type,
            format: row => cleanUp(row.third_party_type)
        },
        {
            name: 'Industry Type',
            selector: row => row.district_name,
            format: row => cleanUp(row.district_name)
        },
        {
            name: 'Storage Details',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name)
        },
        {
            name: 'Storage Area(Tonnes)',
            selector: row => row.chc_storage_capacity_total,
            format: row => cleanUp(row.chc_storage_capacity_total)
        },
        {
            name: 'Equipments',
            selector: row => row.chc_storage_capacity_total,
        },
      
        {
            name: 'District',
            selector: row => row.district_name,
            format: row => cleanUp(row.district_name)
        },
        {
            name: 'Village',
            selector: row => row.village_name,
            format: row => cleanUp(row.village_name)
        },
        {
            name: 'Block',
            selector: row => row.block_name,
            format: row => cleanUp(row.block_name)
        }
    ]
};
export {dataBase_columns};