import { createSlice } from "@reduxjs/toolkit";
import fileDownload from 'js-file-download';

import axiosClient from "../../apiAccess";

export const summaryReportPost = (formData, navigate) => async (dispatch) => {
  const newData = JSON.stringify(formData);
  dispatch(postLoading());
  try {
    const response = await axiosClient.post(`/annexure/report/?district_id=${formData.decomposer.district}`, newData);
    dispatch(postSuccess());
    localStorage.removeItem('summary_draft');
    navigate("/");
    return response.data
  } catch (error) {
    console.error('Error submitting form', error);
    dispatch(postFailure(error.response));
  }
};

export const summaryReportPut = (formData, navigate) => async (dispatch) => {
  const newData = JSON.stringify(formData);
  dispatch(putLoading());
  try {
    const response = await axiosClient.put(`/annexure/new_report/?district_id=${formData.decomposer.district}`, newData);
    dispatch(putSuccess());
    localStorage.removeItem('summary_draft');
    navigate("/");
    return response.data
  } catch (error) {
    console.error('Error submitting form PUT', error);
    dispatch(putFailure(error.response));
  }
};

export const summaryReportGet = (payload) => async (dispatch) => {
  dispatch(summaryLoading());
  try {
    const response = await axiosClient.get('/annexure/summary_report/', {params: {date: payload}});
    dispatch(summarySuccess(response));
  } catch (e) {
    console.error('Error fetching Summary Data', e);
    dispatch(summaryFailure(e.response));
  }
}

export const getDownloadFile = (districtData) => async (dispatch) => {
  try {
    const response = await axiosClient.post("/annexure/download_report/", districtData, {
      responseType: 'blob'
    });
    fileDownload(response.data, 'report.xlsx')
  } catch (e) {
    console.error('Error downloading district data', e)
  }
};

export const getSummaryDownloadFile = (data) => async (dispatch) => {
  try {
    const response = await axiosClient.post("/annexure/annexure_wise_report/", data, {
      responseType: 'blob'
    });
    fileDownload(response.data, 'report.xlsx')
  } catch (e) {
    console.error('Error downloading summary data', e)
  }
};

const initialState = {
  isLoading: false,
  error: null,
  summary: null,
  summaryLoading: false,
  summaryFailure: null
};

const summaryReportSlice = createSlice({
  name: "summaryReportSlice",
  initialState,
  reducers: {
    postLoading: state => {
      state.isLoading = true;
      state.error = null;
    },
    postSuccess: state => {
      state.isLoading = false;
      state.error = null;
    },
    postFailure: (state,action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    putLoading: state => {
      state.isLoading = true;
      state.error = null;
    },
    putSuccess: state => {
      state.isLoading = false;
      state.error = null;
    },
    putFailure: (state,action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    summaryLoading: state => {
      state.summaryLoading = true;
      state.summaryFailure = null;
      state.summary = null;
    },
    summarySuccess: (state, action) => {
      state.summaryLoading = false;
      state.summary = action.payload?.data
      state.summaryFailure = null;
    },
    summaryFailure: (state, action) => {
      state.summaryLoading = false;
      state.summary = null
      state.summaryFailure = action.payload;
    },
  }
});

export const {postSuccess, postLoading, postFailure, putSuccess, putLoading, putFailure, summarySuccess, summaryLoading, summaryFailure} = summaryReportSlice.actions;

// export const { loginStart, loginSuccess, loginFailure } = summaryReportSlice.actions;
export default summaryReportSlice.reducer;
