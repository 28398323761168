import { useEffect, useState } from 'react';
import { NumInput } from '../components';

export { Achievement };

function Achievement(props) {
    const [values, setValues] = useState(props.data)
    const [totals, setTotals] = useState({
        "physical.total_financial_expenditure": false,
        "finanacial.total_financial_expenditure": false
    });
    const bubble = (key, val) => {
        const [parent, child] = key.split('.');
        var parentObj = {...values[parent], [child]: val}
        if (Object.keys(totals).includes(key)) {
            setTotals({...totals, [key]: true});
        } else {
            parentObj = handleTotals(parentObj, parent);
        }
        props.callback('achievement_iec_activities_under_crm', {...values, [parent]: parentObj});
    };

    function handleTotals(nextValues, parentKey) {
        const [totalKey, totalValue] = Object.entries(totals).find(([name]) => name.indexOf(parentKey) === 0);
        const [totalParent, totalChild] = totalKey.split('.');
        if (totalValue) return nextValues;
        let sum = 0;
        Object.entries(nextValues)
            .filter(([name, val]) => name !== totalChild && !isNaN(parseFloat(val)))
            .forEach(([name, val]) => sum += parseFloat(val));
        nextValues[totalChild] = `${sum}`;
        return nextValues;
    }

    useEffect(() => {
        setValues(props.data);
    }, [props.data]);

    const flatten = obj => {
        var o = {};
        for (var i in obj) {
            if (!obj.hasOwnProperty(i)) continue;
            if ((typeof obj[i]) == 'object' && obj[i] !== null) {
                var flatO = flatten(obj[i]);
                for (var x in flatO) {
                    if (!flatO.hasOwnProperty(x)) continue;
                    // added line below because data we GET from API send key as 'finanacial'
                    // but expects it back as 'financial' when POSTing new data
                    // if (i === 'finanacial') i = 'financial';
                    o[`${i}.${x}`] = flatO[x];
                }
            } else {
                o[i] = obj[i];
            }
        }
        return o;
    }
    const flatProps = Object.assign({}, props, {data: flatten(props.data)});

    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#achievement_iec_activities_under_crm">
                Achievement of IEC Activities under CRM Scheme
                </button>
            </h2>
            <div id="achievement_iec_activities_under_crm" className="accordion-collapse collapse section" data-bs-parent="#summaryForm">
                <div className="accordion-body">
                    <div className="row">
                        <div className="col-md-6">
                            <h6>Awareness programmes conducted at Village Level</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.awareness_programs_conducted_village_level" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.awareness_programs_conducted_village_level" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Awareness programmes conducted at Panchayat Level</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.awareness_programs_conducted_panchayat_level" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.awareness_programs_conducted_panchayat_level" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Awareness programmes conducted at Block Level</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.awareness_programs_conducted_block_level" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.awareness_programs_conducted_block_level" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Awareness programmes conducted at District  Level</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.awareness_programs_conducted_district_level" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.awareness_programs_conducted_district_level" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <h6>Training Programmes conducted</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.training_programs_conducted" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.training_programs_conducted" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>No. of farmers/enterpreneurs trained in these trainings</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.no_of_farmers_trained" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.no_of_farmers_trained" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <h6>Kisan Melas organized</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.kisan_melas_organized" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.kisan_melas_organized" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <h6>Mobilization of schools and colleges through essay completion, painting, debate etc.</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.mobilization_of_schools_colleges_through_essay" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.mobilization_of_schools_colleges_through_essay" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <h6>Demonstration conducted (ha)</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.demonstration_conducted" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.demonstration_conducted" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Exposure visits organized</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.exposure_visits_organized" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.exposure_visits_organized" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Field days organized</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.field_days_organized" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.field_days_organized" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Advertisement in Print media</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.advertisement_in_print_media" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.advertisement_in_print_media" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Hoarding/Banners at prominent places</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.hoarding_fixed" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.hoarding_fixed" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <h6>Column / Articles in newspaper and magazines etc.</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.articles_in_newspaper" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.articles_in_newspaper" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Poster/Banner placed</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.poster_banner_placed" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.poster_banner_placed" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <h6>Publicity material - leaflets/pamphlets etc. distributed</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.publicity_material" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.publicity_material" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>TV programmes/ panel discussions Doordarshan/ DD-Kisan and other private channels</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.tv_programmes" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.tv_programmes" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Wall Painting</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.wall_painting" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.wall_painting" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <h6>Webinars Organized/ Lectures Delivered in Webinars on CRM</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.webinars_organized_lectures_delivered_in_webinars_on_crm" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.webinars_organized_lectures_delivered_in_webinars_on_crm" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <h6>Nos. of Demonstration Van deployed on Hiring basis</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.demonstration_van" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.demonstration_van" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <h6>School/College level activities</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.school_college_level_activities" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.school_college_level_activities" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h6>No of activities</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.no_of_activities" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.no_of_activities" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>No of student participate</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.no_of_student_participate" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.no_of_student_participate" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6>Total Financial Expenditure</h6>
                            <div className="row">
                                <NumInput bubble={bubble} name="physical.total_financial_expenditure" label="Physical" layout="col-md-6" {...flatProps}></NumInput>
                                <NumInput bubble={bubble} name="finanacial.total_financial_expenditure" label="Financial" layout="col-md-6" {...flatProps}></NumInput>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}