const zero = cell => {
    if (!cell || !cell.value) return 0;
    return cell.value;
}
const round = (cell, roundTo = 2) => {
    if (!cell || !cell || isNaN(parseFloat(cell))) return cell;
    let rounded = parseFloat(cell).toFixed(roundTo);
    return (rounded.split('.')[1] === '00') ? rounded.split('.')[0] : rounded;
}

export const columns = {
    paddy_harvesting: [
        {
            Header: 'District',
            accessor: 'district_name'
        },
        {
            Header: 'Area Under Paddy (in Acres)',
            columns: [
                {
                    Header: 'Basmati',
                    accessor: 'area_under_paddy_basmati_acres',
                    Cell: zero,
                },
                {
                    Header: 'Non Basmati',
                    accessor: 'area_under_paddy_non_basmati_acres',
                    Cell: zero,
                },
            ]
        },
        {
            Header: 'Paddy area harvested till date (in acres)',
            accessor: 'paddy_area_harvested_till_date_acres',
            Cell: zero,
            width: '200px'
        },
        {
            Header: 'Percentage of harvesting (in acres)',
            accessor: 'percentage_of_harvesting_acres',
            Cell: zero,
            width: '15px'
        },
    ],
    decomposer: [
        {
            Header: 'District',
            accessor: 'district_name',
            width: '100px'
        },
        {
            Header: 'Decomposer spray by department (through kit provide)',
            columns: [
                {
                    Header: 'Kits delivered to farmers',
                    accessor: 'kits_delivered_farmer',
                    Cell: zero,
                },
                {
                    Header: 'Sprayed on field (in acres)',
                    accessor: 'sprayed_on_farmer_field',
                    Cell: zero,
                },
                {
                    Header: 'Registrations on portal',
                    accessor: 'registration_on_portal',
                    Cell: zero,
                },
                {
                    Header: 'No. of farmers benifited',
                    accessor: 'farmers_benifitted',
                    Cell: zero,
                },
            ]
        },
    ],
    dsr_enforcement_measures: [
        {
            Header: 'District',
            accessor: 'district_name',
            width: '100px'
        },
        {
            Header: 'Total No. of AFL reported',
            columns: [
                {
                    Header: 'HARSAC',
                    accessor: 'afl_harsac',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Other Sources',
                    accessor: 'afl_other',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Total',
                    accessor: 'total_afl',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Locations with Fire Incidents',
            columns: [
                {
                    Header: 'Found',
                    accessor: 'locations_fire_incidents',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Not Found',
                    accessor: 'locations_no_fire_incidents',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'AFL found on Non-Agricultural Land/Field/Garbage',
            accessor: 'afl_non_agri_land',
            Cell: cell => zero(cell)
        },
        {
            Header: 'AFL found on Agricultural Land',
            accessor: 'afl_agri_land',
            Cell: cell => zero(cell)
        },
        {
            Header: 'Complaints/FIRs lodged by the Department',
            accessor: 'fir_lodged_dept',
            Cell: cell => zero(cell)
        },
        {
            Header: 'No. of Challan Imposed',
            columns: [
                {
                    Header: 'No. of Challan',
                    accessor: 'challan_imposed',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Penalty (in Rupees)',
                    accessor: 'penalty_imposed',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Fine Collected',
                    accessor: 'fine_collected',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Fire Tenders used',
            accessor: 'use_fire_tendor',
            Cell: cell => zero(cell)
        },
    ],
    achievement_iec_activities_under_crm: [
        {
            Header: 'District',
            accessor: 'district_name'
        },
        {
            Header: 'Awareness programmes conducted at Village Level',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-awareness_programs_conducted_village_level',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-awareness_programs_conducted_village_level',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Awareness programmes conducted at Panchayat Level',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-awareness_programs_conducted_panchayat_level',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-awareness_programs_conducted_panchayat_level',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Awareness programmes conducted at Block Level',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-awareness_programs_conducted_block_level',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-awareness_programs_conducted_block_level',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Awareness programmes conducted at District Level',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-awareness_programs_conducted_district_level',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-awareness_programs_conducted_district_level',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Training Programmes conducted',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-training_programs_conducted',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-training_programs_conducted',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'No. of farmers trained',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-no_of_farmers_trained',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-no_of_farmers_trained',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Kisan Melas organized',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-kisan_melas_organized',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-kisan_melas_organized',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Demonstration conducted (hectares)',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-demonstration_conducted',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-demonstration_conducted',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Exposure visits organized',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-exposure_visits_organized',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-exposure_visits_organized',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Field days organized',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-field_days_organized',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-field_days_organized',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Advertisement in Print media',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-advertisement_in_print_media',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-advertisement_in_print_media',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Hoarding/Banners at prominent places',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-hoarding_fixed',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-hoarding_fixed',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Publicity material distributed',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-publicity_material',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-publicity_material',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'TV Programmes / Panel Discussions',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-tv_programmes',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-tv_programmes',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Wall Paintings',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-wall_painting',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-wall_painting',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Demonstration Van deployed on Hiring basis',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-demonstration_van',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-demonstration_van',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'School/College level activities',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-school_college_level_activities',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-school_college_level_activities',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'No. of activities',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-no_of_activities',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-no_of_activities',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'No. of student participate',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-no_of_student_participate',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-no_of_student_participate',
                    Cell: cell => zero(cell)
                }
            ]
        },
        {
            Header: 'Total Financial Expenditure',
            columns: [
                {
                    Header: 'Physical',
                    accessor: 'physical-total_financial_expenditure',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Financial',
                    accessor: 'financial-total_financial_expenditure',
                    Cell: cell => zero(cell)
                }
            ]
        }
    ],
    area_covered_crm_machinery: [
        {
            Header: 'District',
            accessor: 'district_name'
        },
        {
            Header: 'Rake and Slasher',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'crm-straw_baler_units_with_rake_slasher',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-straw_baler_units_with_rake_slasher',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Super Seeder',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'crm-super_with_super_seeder',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-super_with_super_seeder',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Reversible MB Plough',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'crm-super_with_reversible_plough',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-super_with_reversible_plough',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Happy Seeder',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'crm-super_with_happy_seeder',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-super_with_happy_seeder',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Zero tillage',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'crm-super_with_zero_tillage',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-super_with_zero_tillage',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Total Area (in Acres)',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'crm-total_area_covered',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-total_area_covered',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Grand Total',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'crm-grand_total',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-grand_total',
                    Cell: cell => zero(cell)
                },
            ]
        },
    ],
    progress_regarding_incentive_insitu_exsitu: [
        {
            Header: 'District',
            accessor: 'district_name'
        },
        {
            Header: 'Details uploaded on Portal',
            columns: [
                {
                    Header: 'No. of Farmers',
                    accessor: 'details_uploaded_on_portal-no_of_farmers',
                    Cell: cell => round(zero(cell))
                },
                {
                    Header: 'Area (in Acres)',
                    accessor: 'details_uploaded_on_portal-area',
                    Cell: cell => round(zero(cell))
                },
                {
                    Header: 'Incentive Amount (in Lakhs)',
                    accessor: 'details_uploaded_on_portal-incentive_amount',
                    Cell: cell => round(zero(cell))
                }
            ]
        },
        {
            Header: 'Approved by DLEC',
            columns: [
                {
                    Header: 'No. of Farmers',
                    accessor: 'approved_by_dlec_till_date-no_of_farmers',
                    Cell: cell => round(zero(cell))
                },
                {
                    Header: 'Area (in Acres)',
                    accessor: 'approved_by_dlec_till_date-area',
                    Cell: cell => round(zero(cell))
                },
                {
                    Header: 'Incentive Amount (in Lakhs)',
                    accessor: 'approved_by_dlec_till_date-incentive_amount',
                    Cell: cell => round(zero(cell))
                }
            ]
        },
        {
            Header: 'Total expected area managed details during current season',
            columns: [
                {
                    Header: 'No. of Farmers',
                    accessor: 'total_expected_area_managed_details-no_of_farmers',
                    Cell: cell => round(zero(cell))
                },
                {
                    Header: 'Area (in Acres)',
                    accessor: 'total_expected_area_managed_details-area',
                    Cell: cell => round(zero(cell))
                },
                {
                    Header: 'Incentive Amount (in Lakhs)',
                    accessor: 'total_expected_area_managed_details-incentive_amount',
                    Cell: cell => round(zero(cell))
                }
            ]
        },
        {
            Header: 'Incentive to Gaushalas',
            columns: [
                {
                    Header: 'No. of Gaushalas',
                    accessor: 'incentive_to_gaushalas-no_of_gaushalas',
                    Cell: cell => round(zero(cell))
                },
                {
                    Header: 'Paddy straw consumed in MT',
                    accessor: 'incentive_to_gaushalas-paddy_Straw_consumed',
                    Cell: cell => round(zero(cell))
                },
                {
                    Header: 'Incentive Amount',
                    accessor: 'incentive_to_gaushalas-incentive',
                    Cell: cell => round(zero(cell))
                }
            ]
        },
        {
            Header: 'Award',
            columns: [
                {
                    Header: 'From Red to Green zone',
                    accessor: 'award_to_panchayats-from_red_to_green_zone',
                    Cell: cell => round(zero(cell))
                },
                {
                    Header: 'From Yellow to Green zone',
                    accessor: 'award_to_panchayats-from_green_to_yellow_zone',
                    Cell: cell => round(zero(cell))
                },
            ]
        },
    ],
    utilization_in_industries: [
        {
            Header: 'District',
            accessor: 'district_name'
        },
        {
            Header: 'Tentative straw generation (in tonnes)',
            accessor: 'tentative_straw_generation_tonnes',
            Cell: cell => zero(cell)
        },
        {
            Header: 'Nos. of industries in the vicinity of the district utilizing paddy straw',
            columns: [
                {
                    Header: 'A',
                    accessor: 'industries_vicinity_district_utilizing_paddy_straw_1',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'B',
                    accessor: 'industries_vicinity_district_utilizing_paddy_straw_2',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Paddy straw procured by industries (in tonnes)',
            accessor: 'paddy_straw_produced_by_industries_tonnes',
            Cell: cell => zero(cell)
        },
    ],
    water_conservation_techniques: [
        {
            Header: 'District',
            accessor: 'district_name'
        },
        {
            Header: 'Laser Land Leveller',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'chc-laser_land_leveller',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-laser_land_leveller',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Other',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'chc-other',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-other',
                    Cell: cell => zero(cell)
                },
            ]
        },
        {
            Header: 'Grand Total',
            columns: [
                {
                    Header: 'CHC',
                    accessor: 'chc-grand_total',
                    Cell: cell => zero(cell)
                },
                {
                    Header: 'Individual',
                    accessor: 'individual-grand_total',
                    Cell: cell => zero(cell)
                },
            ]
        },
    ]
};