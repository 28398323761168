
import axios from 'axios';

 const config = {
	prod: "https://crmharyana.com:8090",
	pre_prod: "https://103.35.121.208:8090"
}
export const baseURL = config.prod;

const axiosClient = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'application/json',
	}
});

axiosClient.interceptors.request.use(
	async (axiosConf) => {
		
		const token = await localStorage.getItem('authToken');
		if (token) {
			axiosConf.headers.Authorization = `Token ${token}`;
			axiosConf.headers['Content-Type']='application/json'
		}

		return axiosConf;
	},
	(error) => Promise.reject(error)
);

export default axiosClient

export const axiosPostFormData = async({apiPath, formData}) => {
	try{
		const url = `${baseURL}/${apiPath}`
		const token = await localStorage.getItem('authToken');
		
		const response = await axios.postForm(url, formData, {headers: {
		  'Content-Type': 'multipart/form-data',
		  'Authorization': `Token ${token}`
		}});
		return response
	  }
	  catch(err){
		throw err
	  }
}