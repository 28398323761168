import { useEffect, useState } from 'react';
import { NumInput } from '../components';

export { Decomposer };

function Decomposer(props) {
    const [values, setValues] = useState(props.data)
    const bubble = (key, val) => {
        props.callback('decomposer', {...values, [key]: val});
    };
    
    useEffect(() => {
        setValues(props.data);
    }, [props.data]);
    
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#decomposer">
                    Decomposer
                </button>
            </h2>
            <div id="decomposer" className="accordion-collapse collapse section" data-bs-parent="#summaryForm">
                <div className="accordion-body">
                    {/* <NumInput bubble={bubble} name="area_treated_upl" label="Area in Acres treated with decomposer spray by UPL for other organization" layout="col-lg-7" {...props}></NumInput> */}
                    <h6>Decomposer spray by department (through kit provide)</h6>
                    <div className="row">
                        <NumInput bubble={bubble} name="decomposer_sprayed_by_dept" label="Decomposer spray by department" layout="col-lg-3 col-md-6" {...props}></NumInput>
                    </div>
                    <hr />
                    <div className="row">
                        <NumInput bubble={bubble} name="kits_delivered_farmer" label="No. of Kits delivered to farmers" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="sprayed_on_farmer_field" label="Applied/Sprayed on farmers field (in acres)" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="registration_on_portal" label="Registration on portal for 1000/- per( in acres)" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="farmers_benifitted" label="No. of farmers benifited" layout="col-lg-3 col-md-6" {...props}></NumInput>
                    </div>
                </div>
            </div>
        </div>
    )
}