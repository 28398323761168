function CustomTableEmptyState(props) {
    const {reset, columnCount=0} = props;
    return (
        <>
            <tr>
                <td className="no-data" colSpan={columnCount}>
                    <span>No Data Found. </span>
                    {reset ? <span>
                        <u style={{cursor: 'pointer'}} onClick={reset}>Click here</u> to reset filters.
                    </span> : ''}
                </td>
            </tr>
        </>
    )
}
export {CustomTableEmptyState};