import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../apiAccess";

export const getDataBaseSource = createAsyncThunk('dataBaseSlice', async (payload, thunkAPI) => {
  const [sourceName, page = 2, district_id = "all"] = payload;
  let params = {};
  if (sourceName === 'farmer_db') Object.assign(params, {page}, {district_id});
  try {
    const response = await axiosClient.get(`/annexure/${sourceName}/`, {params});
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getDataBaseCounts = createAsyncThunk('dataBaseCount', async (district_id = 'all', thunkAPI) => {
  try {
    const response = await axiosClient.get("/annexure/db_count/", {params: {district_id}});
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const dataBaseSlice = createSlice({
    name: 'dataBaseSlice',
    initialState: {
      loading: false,
      error: null,
      data: null,
      dataBaseCounts: {}
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getDataBaseSource.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataBaseSource.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getDataBaseSource.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(getDataBaseCounts.pending, (state) => {
          return { ...state, countLoading: true }
        })
        .addCase(getDataBaseCounts.fulfilled, (state, action) => {
          return { ...state, countLoading: false, getDataBaseCounts: action?.payload }
        })
        .addCase(getDataBaseCounts.rejected, (state, action) => {
          return { ...state, countLoading: false }
        });
    },
  });
  
  export default dataBaseSlice.reducer;