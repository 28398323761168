// formSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient, { axiosPostFormData } from '../../apiAccess';

const initialState = {
  events: null,
  isLoading: false,
  error: null,
  isAuthenticated: false,
};

export const submitFormData = createAsyncThunk('submitForm', async (formData) => {
  try {
    const response = await axiosPostFormData({ apiPath: 'event/add_event/', formData: formData });
    return response.data;
   
  } catch (error) {
    throw error;
  }
});

const createEventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    eventStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    eventCreated: (state, action) => {
      state.isLoading = false;
      state.events = action.payload;
      state.isAuthenticated = true;
    },
    eventFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitFormData.pending, (state) => {
        // Handle pending action if needed
      })
      .addCase(submitFormData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.events = action.payload; 
        state.isAuthenticated = true;
      })
      .addCase(submitFormData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { eventStart, eventCreated, eventFailure } = createEventSlice.actions;

export default createEventSlice.reducer;
