// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #F8F9FC;
}
.activities-card {
    border-color: #1F604045;
    border-radius: 12px;
    background-color: #F6FFFB;
    width: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}
.card-amount {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
}
.village-link, .village-link:hover {
    color: var(--primary-color);
    font-weight: 500;
}

.custom-table-container {
    max-width: 100%;
    height: 50vh;
    overflow: auto;
}

#village_table thead {
    position: sticky;
    top: 0;
    z-index: 100;
}
.card_section{
    gap: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/activities/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,YAAY;AAChB;AACA;IACI,SAAS;AACb","sourcesContent":["body {\n    background-color: #F8F9FC;\n}\n.activities-card {\n    border-color: #1F604045;\n    border-radius: 12px;\n    background-color: #F6FFFB;\n    width: 15rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    overflow: hidden;\n}\n.card-amount {\n    color: var(--primary-color);\n    font-weight: 600;\n    font-size: 1.5rem;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n.village-link, .village-link:hover {\n    color: var(--primary-color);\n    font-weight: 500;\n}\n\n.custom-table-container {\n    max-width: 100%;\n    height: 50vh;\n    overflow: auto;\n}\n\n#village_table thead {\n    position: sticky;\n    top: 0;\n    z-index: 100;\n}\n.card_section{\n    gap: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
