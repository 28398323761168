// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-table {
    border-collapse:collapse;
    border-spacing: 1;
    width: 100%;
}

.react-table thead, #summary_table thead th.sticky-col {
    background-color: #D8E9FF;
}

.react-table thead th{
    font-weight: 500;
}
.react-table tbody td, .react-table thead th {
    border: none;
}
.react-table thead th {
    outline: 1px solid white;
    outline-offset: -1px;
}
.react-table thead tr:first-child th:first-child {
    border-top-left-radius: 12px;
}
.react-table thead tr:first-child th:last-child {
    border-top-right-radius: 12px;
}
.react-table thead th[colspan]:not([colspan="1"]) {
    text-align: center;
}
.react-table tbody tr:nth-of-type(2n), .react-table tbody tr:nth-of-type(2n) td.sticky-col {
    background-color: #F8F9FC;
}
.react-table tbody tr:nth-of-type(2n-1), .react-table tbody tr:nth-of-type(2n-1) td.sticky-col {
    background-color: #FFF;
}

.custom-pagination button[disabled] {
    opacity: 0.65;
    cursor: none;
}
.custom-pagination span:nth-child(2n - 1) {
    display: inline-block;
    text-align: center;
    min-width: 80px;
}
.react-table .no-data, .react-table .is-loading {
    text-align: center;
    padding: 2rem 0;
}`, "",{"version":3,"sources":["webpack://./src/components/table.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;AAChB;AACA;IACI,wBAAwB;IACxB,oBAAoB;AACxB;AACA;IACI,4BAA4B;AAChC;AACA;IACI,6BAA6B;AACjC;AACA;IACI,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".react-table {\n    border-collapse:collapse;\n    border-spacing: 1;\n    width: 100%;\n}\n\n.react-table thead, #summary_table thead th.sticky-col {\n    background-color: #D8E9FF;\n}\n\n.react-table thead th{\n    font-weight: 500;\n}\n.react-table tbody td, .react-table thead th {\n    border: none;\n}\n.react-table thead th {\n    outline: 1px solid white;\n    outline-offset: -1px;\n}\n.react-table thead tr:first-child th:first-child {\n    border-top-left-radius: 12px;\n}\n.react-table thead tr:first-child th:last-child {\n    border-top-right-radius: 12px;\n}\n.react-table thead th[colspan]:not([colspan=\"1\"]) {\n    text-align: center;\n}\n.react-table tbody tr:nth-of-type(2n), .react-table tbody tr:nth-of-type(2n) td.sticky-col {\n    background-color: #F8F9FC;\n}\n.react-table tbody tr:nth-of-type(2n-1), .react-table tbody tr:nth-of-type(2n-1) td.sticky-col {\n    background-color: #FFF;\n}\n\n.custom-pagination button[disabled] {\n    opacity: 0.65;\n    cursor: none;\n}\n.custom-pagination span:nth-child(2n - 1) {\n    display: inline-block;\n    text-align: center;\n    min-width: 80px;\n}\n.react-table .no-data, .react-table .is-loading {\n    text-align: center;\n    padding: 2rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
