function isInvalid(obj) {
    if (!obj) return false;
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] === null || obj[key] === 0 || obj[key] === '0' || obj[key] === '') continue;  
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                if (!isInvalid(obj[key])) return false
            } else {
                return false;
            }
        }
    }
    return true;
}
export {isInvalid};