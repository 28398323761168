function DistrictDropdown(props) {
    const {data = [], val, callback, isLoading} = props;
    return (
        <>
            {isLoading ? 
                <select disabled className="form-select w-auto mx-1">
                    <option value="">Loading...</option>
                </select> :
                <select onChange={callback} value={val} className="form-select w-auto mx-1">
                    {
                        data.map((item, i) => {
                            return (<option key={i} value={item.value}>{item.name}</option>)
                        })
                    }
                </select>
            }
        </>
    )
}
export {DistrictDropdown};
