import React, { useEffect, useState } from 'react'
import {Navbar} from '../components/';
import { DistrictData } from '../district_data';
import { Link } from 'react-router-dom';
import { data as zeroData } from '../district_data/summary_keys';
import { ToastContainer, toast } from "react-toastify";
import { getReportData } from '../redux/slice/getUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { isInvalid } from '../district_data/functions';
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


const Update = () => {
  const getUserData = JSON.parse(localStorage.getItem("userData"));
  const aae_and_not_admin = getUserData?.is_state_head === 0 && !getUserData?.is_admin;
  let report = useSelector((state) => state?.getUserSlice?.data);
  const dispatch = useDispatch();
  let [draft, setDraft] = useState();
  const [sectionIds, setSectionIds] = useState();
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [dateChanged, hasDateChanged] = useState(false);

  useEffect(() => {
    
    try {
      if (localStorage.hasOwnProperty('summary_draft')) {
        let parsed = JSON.parse(localStorage.getItem('summary_draft'));
        if (!isInvalid(parsed)) {
          setDraft(parsed);
          toast.info('Data you entered previously has been restored.')
        }
      }
    } catch (e) {
      console.error('Unable to parse draft data from localStorage');
    }
  }, [])

  useEffect(() => {
    dispatch(getReportData([getUserData?.district_id, date]));
  }, [date])

  useEffect(() => {
    if (!report) return;
    setSectionIds({
      paddy_harvesting: report?.paddy_harvesting?.id,
      decomposer: report?.decomposer?.id,
      dsr_enforcement_measures: report?.dsr_enforcement_measures?.id,
      utilization_in_industries: report?.utilization_in_industries?.id,
      achievement_iec_activities_under_crm: {
        finanacial: report?.achievement_iec_activities_under_crm?.finanacial?.id,
        physical: report?.achievement_iec_activities_under_crm?.physical?.id,
      },
      area_covered_crm_machinery: {
        individual: report?.area_covered_crm_machinery?.individual?.id,
        chc: report?.area_covered_crm_machinery?.chc?.id
      },
      progress_regarding_incentive_insitu_exsitu: {
        details_uploaded_on_portal: report?.progress_regarding_incentive_insitu_exsitu?.details_uploaded_on_portal?.id,
        approved_by_dlec_till_date: report?.progress_regarding_incentive_insitu_exsitu?.approved_by_dlec_till_date?.id,
        total_expected_area_managed_details: report?.progress_regarding_incentive_insitu_exsitu?.total_expected_area_managed_details?.id,
        incentive_to_gaushalas: report?.progress_regarding_incentive_insitu_exsitu?.incentive_to_gaushalas?.id,
        award_to_panchayats: report?.progress_regarding_incentive_insitu_exsitu?.award_to_panchayats?.id
      },
      water_conservation_techniques: {
        chc: report?.water_conservation_techniques?.chc?.id,
        individual: report?.water_conservation_techniques?.individual?.id
      }
    });
  }, [report]);

  function whichData() {
    if (draft && !isInvalid(draft)) {
      return Object.assign({}, draft);
    }
    if (report) {
      const next = deepClone(report, ['modified_on', 'modified_By', 'created_on', 'created_by', 'id', 'user_id', 'district', 'saved_on_date']);
      if (!isInvalid(next)) {
        return next;
      }
    }
    return zeroData;
  }

  function deepClone(obj, keysToExclude = []) {
    if (typeof obj !== 'object' || obj === null) return obj;
    if (Array.isArray(obj)) return obj.map((item) => deepClone(item, keysToExclude));
  
    const clone = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && !keysToExclude.includes(key)) {
        clone[key] = deepClone(obj[key], keysToExclude);
      }
    }
    return clone;
  }

  function changeDate(next) {
    hasDateChanged(true);
    localStorage.removeItem('summary_draft');
    setDate(dayjs(next).format('YYYY-MM-DD'));
  }

  return (
    <>
      <Navbar/>
      <ToastContainer hideProgressBar pauseOnHover={false} position="bottom-right"/>
      <div className="container mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className='d-flex align-items-center'>
            <Link to="/" className="btn btn-light mb-2 me-3" title="Go Back">&#x1F878;</Link>
            <h4>Update Details</h4>
          </div>
          <div>
            {aae_and_not_admin ? <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker className="custom_datepicker" value={dayjs(date)} minDate={dayjs().subtract(4, 'd')} onChange={changeDate} disableFuture/>
            </LocalizationProvider> : ''}
          </div>
        </div>
        <hr />
        <DistrictData 
          saveDraft={!dateChanged} 
          data={whichData()} 
          district_id={getUserData?.district_id} 
          date={date} 
          section_ids={!isInvalid(sectionIds) ? sectionIds : undefined}/>
      </div>
    </>
  )
}

export default Update
