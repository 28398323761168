import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import "../EventPages/eventStyle.css";
import { GrUpload } from "react-icons/gr";
import { RiDeleteBinLine } from "react-icons/ri";
import upload from "../assets/Image_Upload.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, getAllDistricts } from "../redux/slice/getUserSlice";
import { ToastContainer, toast } from 'react-toastify';
import {
  getFilteredBlockList,
  getFilteredVillageList,
} from "../redux/slice/marketSlice";
import { isFormValid } from "./formValidation";
import { submitFormData } from "../redux/slice/createEventSlice";
import { formatFileSize } from "./formatFileSize";




const EventForm = (props) => {

  const [selectedImages, setSelectedImages] = useState([]);
  const [formValues, setFormValues] = useState({
    event_name: "",
    event_date: null,
    event_time: null,
    type_of_participants: "",
    total_no_of_participants: "",
    event_type: "",
    district_name: "",
    district_id: null,
    block_name: "",
    block_id: null,
    village_name: "",
    village_code: null,
    event_description: "",
    event_file: selectedImages,
  });
  

  const [districtList, setDistrictList] = useState([]); // To store the list of districts
  const [blockList, setBlockList] = useState(null); // To store the list of blocks
  const [villageList, setVillageList] = useState(null); // To store the list of villages
  const [formErrors, setFormErrors] = useState({});
  const [imageUploadError, setImageUploadError] = useState(null);
  const [characterCount, setCharacterCount] = useState(0); 

  const dispatch = useDispatch();
  const allDistricts = useSelector(
    (state) => state?.getUserSlice?.districtList
  );
  const getResponse = useSelector(
    (state) => state?.createEventSlice?.events
  );

 

  const getVillage = useSelector((state) => state?.marketSlice?.villageList);
  const getBlocks = useSelector((state) => state?.marketSlice?.blockList);

  const handleDistrictChange = (districtId, districtName) => {
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors.district_name;
    setFormErrors(updatedFormErrors);
    dispatch(getFilteredBlockList(districtId));
    setFormValues({
      ...formValues,
      district_name: districtName,
      district_id: districtId,
    });
    const updatedValues = {
      ...formValues,
      district_name: districtName,
      district_id: districtId,
    };

    const fieldErrors = isFormValid(updatedValues);
    setFormErrors({
      ...formErrors,
      district_name: fieldErrors.district_name,
    });
  };

  const handleBlockChange = (blockId, blockName) => {
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors.block_name;
    setFormErrors(updatedFormErrors);
    dispatch(getFilteredVillageList(blockId));
    setFormValues({
      ...formValues,
      block_name: blockName,
      block_id: blockId,
    });
    const updatedValues = {
      ...formValues,
      block_name: blockName,
      block_id: blockId,
    };

    const fieldErrors = isFormValid(updatedValues);
    setFormErrors({
      ...formErrors,
      block_name: fieldErrors.block_name,
    });
  };

  const handleCancel = () => {
    props.toggleComponents(false);
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const newImages = Array.from(files);

    // Validate the selected files
    if (newImages.length === 0) {
      setImageUploadError("Please select at least one image.");
    } else if (newImages.length > 5) {
      setImageUploadError("You can upload a maximum of 5 images.");
    } else {
      setImageUploadError(""); // Clear the error message if there are no errors
      setSelectedImages([...selectedImages, ...newImages]);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  useEffect(() => {
    dispatch(getAllDistricts());
  }, [dispatch]);

  useEffect(() => {
    if (allDistricts) {
      setDistrictList(allDistricts);
    }
    setBlockList(getBlocks);
    setVillageList(getVillage);
  }, [allDistricts, getBlocks, getVillage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = {
      ...formValues,
      [name]: value,
    };
    setFormValues(updatedValues);
    const errors = isFormValid(updatedValues);
    setFormErrors({
      ...formErrors,
      event_name: errors.event_name,
    });
  };
  const handleDateChange = (date) => {
    // Clear the error message for event_date
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors.event_date;
    setFormErrors(updatedFormErrors);

    if (date) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setFormValues({
        ...formValues,
        event_date: formattedDate,
      });
    } else {
      setFormValues({
        ...formValues,
        event_date: null,
      });
    }
  };

  const handleTimeChange = (time) => {
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors.event_time;
    setFormErrors(updatedFormErrors);

    if (time) {
      const formattedTime = dayjs(time).format("HH:mm:ss");
      setFormValues({
        ...formValues,
        event_time: formattedTime,
      });
    } else {
      setFormValues({
        ...formValues,
        event_time: null,
      });

      const updatedValues = {
        ...formValues,
        event_time: null,
      };

      const fieldErrors = isFormValid(updatedValues);

      setFormErrors({
        ...formErrors,
        event_time: fieldErrors.event_time,
      });
    }
  };

  const handleTypeOfParticipantsChange = (e) => {
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors.type_of_participants;
    setFormErrors(updatedFormErrors);
    setFormValues({
      ...formValues,
      type_of_participants: e.target.value,
    });
    const updatedValues = {
      ...formValues,
      type_of_participants: e.target.value,
    };

    const fieldErrors = isFormValid(updatedValues);
    setFormErrors({
      ...formErrors,
      type_of_participants: fieldErrors.type_of_participants,
    });
  };

  const handleTotalParticipantsChange = (e) => {
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors.total_no_of_participants;
    setFormErrors(updatedFormErrors);

    setFormValues({
      ...formValues,
      total_no_of_participants: e.target.value,
    });

    const updatedValues = {
      ...formValues,
      total_no_of_participants: e.target.value,
    };

    const fieldErrors = isFormValid(updatedValues);

    setFormErrors({
      ...formErrors,
      total_no_of_participants: fieldErrors.total_no_of_participants,
    });
  };

  const handleEventTypeChange = (e) => {
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors.event_type;
    setFormErrors(updatedFormErrors);
    setFormValues({
      ...formValues,
      event_type: e.target.value,
    });
    const updatedValues = {
      ...formValues,
      event_type: e.target.value,
    };


    const fieldErrors = isFormValid(updatedValues);
    setFormErrors({
      ...formErrors,
      event_type: fieldErrors.event_type,
    });
  };

  const handleFormSubmit = async(e) => {
    e.preventDefault();
    const validationErrors = isFormValid(formValues);
    setFormErrors(validationErrors);
    setImageUploadError("");
    if (selectedImages.length === 0) {
      setImageUploadError("Please upload at least one image.");
    } else if (selectedImages.length > 5) {
      setImageUploadError("You can upload a maximum of 5 images.");
    }

    else if (Object.keys(validationErrors).length === 0 && !imageUploadError) {
      const formData = new FormData();
      // Append form field values to the FormData
      formData.append("event_name", formValues.event_name);
      formData.append("event_date", formValues.event_date);
      formData.append("event_time", formValues.event_time);
      formData.append("type_of_participants", formValues.type_of_participants);
      formData.append(
        "total_no_of_participants",
        formValues.total_no_of_participants
      );
      formData.append("event_type", formValues.event_type);
      formData.append("district_name", formValues.district_name);
      formData.append("district_id", formValues.district_id);
      formData.append("block_name", formValues.block_name);
      formData.append("block_id", formValues.block_id);
      formData.append("village_name", formValues.village_name);
      formData.append("village_code", formValues.village_code);
      formData.append("event_description", formValues.event_description);

      formValues?.event_file?.forEach((item) => {
        formData.append("event_file", item);
      });
      try {
         await dispatch(submitFormData(formData));
        props.toggleComponents(false);
      } catch (error) {
        // Handle any error here
        console.error(error);
      }

    } else {
      setFormErrors(validationErrors);
    }
  };

  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      event_file: selectedImages,
    }));
  }, [selectedImages]);

 
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 ">
            <div className="card shadow">
              <div className="card-body ">
                <form onSubmit={handleFormSubmit}>
                  <div className="row">
                    <div className="col-md-6 verticalline">
                      <div className="mb-3">
                        <label htmlFor="eventName" className="form-label">
                          Name Your Event<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="event_name"
                          value={formValues.event_name}
                          onChange={handleInputChange}
                          className="form-control"
                          id="username"
                          placeholder="Enter Event Name"
                        />
                        {formErrors.event_name && (
                          <div className="text-danger small">
                            {formErrors.event_name}
                          </div>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="password" className="form-label">
                            Event Date<span className="text-danger">*</span>
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="custom_datepicker"
                              name="event_date"
                              renderInput={(params) => <input {...params} />}
                              value={formValues.event_date}
                              onChange={handleDateChange}
                            />
                          </LocalizationProvider>

                          {formErrors.event_date && (
                            <div className="text-danger small">
                              {formErrors.event_date}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                              Start Time<span className="text-danger">*</span>
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                value={formValues.time}
                                onChange={handleTimeChange}
                              />
                            </LocalizationProvider>
                          </div>
                          {formErrors.event_time && (
                            <div className="text-danger small">
                              {formErrors.event_time}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                              Type of Participants
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              value={formValues.type_of_participants}
                              onChange={handleTypeOfParticipantsChange}
                            >
                              <option value="">Select</option>
                              <option value="farmer">Farmer</option>
                              <option value="chc">CHC</option>
                              <option value="farmerAndchc">
                                Farmer and CHC
                              </option>
                            </select>
                          </div>
                          {formErrors.type_of_participants && (
                            <div className="text-danger small">
                              {formErrors.type_of_participants}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="number" className="form-label">
                              Total Number Participants
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="totalNumberParticipants"
                              placeholder="Enter Number"
                              value={formValues?.total_no_of_participants}
                              onChange={handleTotalParticipantsChange}
                            />
                          </div>
                          {formErrors.total_no_of_participants && (
                            <div className="text-danger small">
                              {formErrors.total_no_of_participants}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                              Type of Event
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              value={formValues.event_type}
                              onChange={handleEventTypeChange}
                            >
                              <option value="">Select</option>
                              <option value="village">Village Level</option>
                              <option value="block">Block Level</option>
                              <option value="district">District Level</option>
                            </select>
                          </div>
                          {formErrors.event_type && (
                            <div className="text-danger small">
                              {formErrors.event_type}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="district" className="form-label">
                              District<span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              id="district"
                              // required
                              onChange={(e) => {
                                const selectedDistrictId = e.target.value;
                                const selectedDistrict =
                                  e.target.options[e.target.selectedIndex].text;
                                setFormValues({
                                  ...formValues,
                                  district_name: selectedDistrict,
                                  district_id: selectedDistrictId,
                                });
                                handleDistrictChange(
                                  selectedDistrictId,
                                  selectedDistrict
                                ); // Pass both ID and name
                              }}
                            >
                              <option value="">Select</option>
                              {districtList &&
                                districtList.map((district) => (
                                  <option
                                    key={district.district_id}
                                    value={district.district_id}
                                  >
                                    {district.district_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {formErrors.district_name && (
                            <div className="text-danger small">
                              {formErrors.district_name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="block" className="form-label">
                              Block
                              {(formValues.event_type === "block" || formValues.event_type === "village") && <span className="text-danger">*</span>}
                            </label>
                            <select
                              className="form-select"
                              id="block"
                              // required
                              onChange={(e) => {
                                const selectedBlockId = e.target.value;
                                const selectedBlock =
                                  e.target.options[e.target.selectedIndex].text; // Get the selected block name
                                setFormValues({
                                  ...formValues,
                                  block_name: selectedBlock,
                                  block_id: selectedBlockId,
                                });
                                handleBlockChange(
                                  selectedBlockId,
                                  selectedBlock
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {blockList &&
                                blockList?.map((block) => (
                                  <option
                                    key={block.block_id}
                                    value={block.block_id}
                                  >
                                    {block?.block_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {formErrors.block_name && (
                            <div className="text-danger small">
                              {formErrors.block_name}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="village" className="form-label">
                              Village
                              {formValues.event_type === "district" || formValues.event_type === "block" ||formValues.event_type === "village" && <span className="text-danger">*</span>}
                            </label>
                            <select
                              className="form-select"
                              id="village"
                              // required
                              onChange={(e) => {
                                const selectedVillageId = e.target.value;
                                const selectedVillage =
                                  e.target.options[e.target.selectedIndex].text;

                                // Clear the error message for village_name
                                const updatedFormErrors = { ...formErrors };
                                delete updatedFormErrors.village_name;
                                setFormErrors(updatedFormErrors);

                                // Update formValues with the new selected values
                                setFormValues({
                                  ...formValues,
                                  village_name: selectedVillage,
                                  village_code: selectedVillageId,
                                });

                                // Validate the changed field
                                const updatedValues = {
                                  ...formValues,
                                  village_name: selectedVillage,
                                  village_code: selectedVillageId,
                                };

                                const fieldErrors = isFormValid(updatedValues);

                                // Update the formErrors state for the specific field
                                setFormErrors({
                                  ...formErrors,
                                  village_name: fieldErrors.village_name,
                                });
                              }}
                            >
                              <option value="">Select</option>
                              {villageList &&
                                villageList?.map((village) => (
                                  <option
                                    key={village?.village_id}
                                    value={village?.village_id}
                                  >
                                    {village?.village_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {formErrors.village_name && (
                            <div className="text-danger small">
                              {formErrors.village_name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="username" className="form-label">
                              Description of Event
                              <span className="text-danger">*</span>
                            </label>
                            <div className="text_area">
                              <textarea
                                className="form-control"
                                id="exampleTextarea"
                                rows="6"
                                placeholder="Type here"
                                value={formValues.event_description}
                                onChange={(e) => {
                                  const newDescription = e.target.value;
                                  const updatedFormErrors = { ...formErrors };
                                  delete updatedFormErrors.event_description;
                                  setFormErrors(updatedFormErrors);
                                  setFormValues({
                                    ...formValues,
                                    event_description: newDescription,
                                  });

                                  const characterCount = newDescription.length;

                                  setCharacterCount(characterCount);

                                  const updatedValues = {
                                    ...formValues,
                                    event_description: newDescription,
                                  };

                                  const fieldErrors =
                                    isFormValid(updatedValues);
                                  setFormErrors({
                                    ...formErrors,
                                    event_description:
                                      fieldErrors.event_description,
                                  });
                                }}
                              ></textarea>

                              <p>{characterCount} characters</p>
                            </div>
                            {formErrors.event_description && (
                              <div className="text-danger small">
                                {formErrors.event_description}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                              Upload Supportning Image
                              <span className="text-danger">*</span>
                            </label>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label
                                htmlFor="fileInput"
                                className="form-label upload_img"
                              >
                                Upload &nbsp;
                                <GrUpload style={{ color: "green" }} />
                              </label>
                              <input
                                type="file"
                                accept="image/*"
                                id="fileInput"
                                // required
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                              />
                            </div>
                            {imageUploadError && (
                              <div className="text-danger small">
                                {imageUploadError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-md-12">
                            {selectedImages.map((image, index) => (
                              <div className="mb-3 img_files" key={index}>
                                <img src={upload} alt="upload_img" />
                                <div className="img_details">
                                  <strong>{image.name}</strong>
                                  <div>
                                    <small>
                                      {
                                        new Date(image.lastModified)
                                          .toLocaleString()
                                          .split(",")[0]
                                      }
                                    </small>
                                    .{" "}
                                    <small>{formatFileSize(image.size)}</small>
                                  </div>
                                </div>
                                <RiDeleteBinLine
                                  className="close_btn"
                                  onClick={() => handleRemoveImage(index)}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10">
                            <div className="mb-3">
                              <ul className="note_lists">
                                <li>Maximum file size for one photo : 2 MB</li>
                                <li> Supporting formate JPGE, PNG</li>
                                <li>Number of files - 1 to 5</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 d-flex gap-4 justify-content-end">
                          <button
                            className="btn btn-outline-success btn_cancel"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button className="btn btn-success btn_large">
                            Create Event
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default EventForm;
