import React, { useState } from "react";
import EventLandingPage from "./EventLandingPage";
import CreateEvent from "./CreateEvent";
import { Navbar } from "../components";
import EventForm from "./EventForm";

const ToggleComponent = () => {
  const [showComponent1, setShowComponent1] = useState(false);
  const toggleComponents = () => {
    setShowComponent1((prevShowComponent1) => !prevShowComponent1);
  };

  return (
    <div>
      <Navbar active="events"/>   

      {showComponent1 ? <CreateEvent toggleComponents={setShowComponent1} /> : <EventLandingPage toggleComponents={toggleComponents} />}
      {/* <EventForm toggleComponents={toggleComponents}/> */}
    </div>
  );
};

export default ToggleComponent;
