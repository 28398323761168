import { useEffect, useState } from 'react';
import { NumInput } from '../components';

export { Progress };

function Progress(props) {
    const [values, setValues] = useState(props.data)
    const bubble = (key, val) => {
        const [parent, child] = key.split('.');
        var parentObj = {...values[parent], [child]: val};
        props.callback('progress_regarding_incentive_insitu_exsitu', {...values, [parent]: parentObj});
    };

    useEffect(() => {
        setValues(props.data);
    }, [props.data]);
    
    const flatten = obj => {
        var o = {};
        for (var i in obj) {
            if (!obj.hasOwnProperty(i)) continue;
            if ((typeof obj[i]) == 'object' && obj[i] !== null) {
                var flatO = flatten(obj[i]);
                for (var x in flatO) {
                    if (!flatO.hasOwnProperty(x)) continue;
                    o[`${i}.${x}`] = flatO[x];
                }
            } else {
                o[i] = obj[i];
            }
        }
        return o;
    }
    const flatProps = Object.assign({}, props, {data: flatten(props.data)});
    
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#progress_regarding_incentive_insitu_exsitu">
                Progress regarding incentive for in-situ/ex-situ management @ Rs. 1000/acre
                </button>
            </h2>
            <div id="progress_regarding_incentive_insitu_exsitu" className="accordion-collapse collapse section" data-bs-parent="#summaryForm">
                <div className="accordion-body">
                    <div>
                        <h6>Details uploaded on Portal till date</h6>
                        <div className="row">
                            <NumInput bubble={bubble} name="details_uploaded_on_portal.no_of_farmers" label="No. of Farmers" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                            <NumInput bubble={bubble} name="details_uploaded_on_portal.area" label="Area (in acres)" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                            <NumInput bubble={bubble} name="details_uploaded_on_portal.incentive_amount" label="Incentive amount (Rs. in Lakh)" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                        </div>
                    </div>
                    <div>
                        <h6>Approved by DLEC till date</h6>
                        <div className="row">
                            <NumInput bubble={bubble} name="approved_by_dlec_till_date.no_of_farmers" label="No. of Farmers" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                            <NumInput bubble={bubble} name="approved_by_dlec_till_date.area" label="Area (in acres)" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                            <NumInput bubble={bubble} name="approved_by_dlec_till_date.incentive_amount" label="Incentive amount (Rs. in Lakh)" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                        </div>
                    </div>
                    <div>
                        <h6>Total expected area managed details during current season</h6>
                        <div className="row">
                            <NumInput bubble={bubble} name="total_expected_area_managed_details.no_of_farmers" label="No. of Farmers" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                            <NumInput bubble={bubble} name="total_expected_area_managed_details.area" label="Area (in acres)" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                            <NumInput bubble={bubble} name="total_expected_area_managed_details.incentive_amount" label="Incentive amount (Rs. in Lakh)" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                        </div>
                    </div>
                    <div>
                        <h6>Incentive to Gaushalas</h6>
                        <div className="row">
                            <NumInput bubble={bubble} name="incentive_to_gaushalas.no_of_gaushalas" label="No of Gausahalas" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                            <NumInput bubble={bubble} name="incentive_to_gaushalas.paddy_Straw_consumed" label="Paddy straw consumed in MT" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                            <NumInput bubble={bubble} name="incentive_to_gaushalas.incentive" label="Incentive Rs." layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                        </div>
                    </div>
                    <div>
                        <h6>Award</h6>
                        <div className="row">
                            <NumInput bubble={bubble} name="award_to_panchayats.from_red_to_green_zone" label="From Red to Green zone" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                            <NumInput bubble={bubble} name="award_to_panchayats.from_green_to_yellow_zone" label="From Yellow to Green zone" layout="col-lg-4 col-md-6" {...flatProps}></NumInput>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}