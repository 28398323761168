import { useEffect, useState } from 'react';
import { NumInput } from '../components';

export { DailyStatus };

function DailyStatus(props) {
    const [values, setValues] = useState(props.data)
    const bubble = (key, val) => {
        props.callback('dsr_enforcement_measures', {...values, [key]: val});
    };

    useEffect(() => {
        setValues(props.data);
    }, [props.data]);

    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dsr_enforcement_measures">
                Daily Status report on Enforcement Measures
                </button>
            </h2>
            <div id="dsr_enforcement_measures" className="accordion-collapse collapse section" data-bs-parent="#summaryForm">
                <div className="accordion-body">
                    <div className="row">
                        <NumInput bubble={bubble} name="afl_harsac" label="AFL Reported by HARSAC" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="afl_other" label="AFL reported by Other Sources" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="total_afl" label="Total AFL Reported" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="locations_no_fire_incidents" label="No. of locations where fire incident not found" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="locations_fire_incidents" label="No. of locations where fire incidents found" layout="col-lg-3 col-md-6" {...props}></NumInput>
                    </div>
                    <hr />
                    <div className="row">
                        <NumInput bubble={bubble} name="afl_non_agri_land" label="No. of AFL found on Non-Agricultural Land/Field/Garbage" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="afl_agri_land" label="No. of AFL found on Agricultural Land" layout="col-lg-3 col-md-6" {...props}></NumInput>
                    </div>
                    <hr />
                    <div className="row">
                        <NumInput bubble={bubble} name="fir_lodged_dept" label="No. of Complaints/FIRs lodged by the Department" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="challan_imposed" label="No. of Challan Imposed" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="penalty_imposed" label="Penalty imposed (in Rupees)" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="fine_collected" label="Fine Collected" layout="col-lg-3 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="use_fire_tendor" label="Use of fire tendor for during the fire" layout="col-lg-3 col-md-6" {...props}></NumInput>
                    </div>
                </div>
            </div>
        </div>
    )
}