function FilterDropdown(props) {
    const {data = [], value, type, defaultSelection, callback, isLoading, sort, autoWidth, isDisabled} = props;

    const cleanUp = (text) => {
        if (!text || !text.length) return text;
        return text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    if (sort) data.sort((a, b) => a.name < b.name ? -1 : 1);
    let dropdownData =  defaultSelection ? [{value: 'all', name: defaultSelection}, ...data] : data;
    
    const handleChange = e => {
        type ? callback(type, e.target.value) : callback(e.target.value);
    }
    return (
        <>
            {isLoading ? 
                <select disabled className="form-select filter-dropdown mx-1">
                    <option value="">Loading...</option>
                </select> :
                <select onChange={handleChange} value={type ? value[type] : value} disabled={isDisabled} className={`form-select filter-dropdown mx-1 ${autoWidth? 'w-auto' : ''}`}>
                    {
                        dropdownData.map((item, i) => {
                            return (<option key={i} value={item.value}>{cleanUp(item.name)}</option>)
                        })
                    }
                </select>
            }
        </>
    )
}
export {FilterDropdown};
