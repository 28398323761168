const customStyles = {
    headRow: {
        style: {
            backgroundColor: '#D8E9FF', // override the row height
            borderTopRightRadius: '.75rem',
            borderTopLeftRadius: '.75rem',
            fontWeight: '700'
        },
    },
    headCells: {
        style: {
            whiteSpace: 'normal',
            paddingLeft: '20px'
        }
    },
    cells: {
        style: {
            whiteSpace: 'normal',
            // overflow: 'auto',
        }
    },
    pagination: {
        style: {
            // backgroundColor: 'blue'
        },
        pageButtonsStyle: {
            // color: 'red',
            backgroundColor: '#D8E9FF',
            borderRadius: '8px',
            margin: '4px'
        }
    }
}
export default customStyles