import React from "react";
import  "./style.css";

function Card(props) {
    const {name, amount} = props;
    const formatAmount = num => {
        if (!num || typeof num === 'string') return num;
        return Number.isInteger(num) ? num : parseFloat(num.toFixed(2))
    };
    return (
        <div className="card activities-card px-2 py-2 text-center">
            <h6>{name}</h6>
            <div className="card-amount">{formatAmount(amount)}</div>
        </div>
    )
}

function CardGroup(props) {
    const {cardNames, data} = props;
    return (
        <div className="d-flex justify-content-around my-3 card_section" >
            {Object.entries(cardNames).map(([keyName, name], i) => {
                return <Card name={name} amount={data && data.hasOwnProperty(keyName) ? data[keyName] : '...'} key={i} />
            })}
        </div>
    )
}
export default CardGroup;