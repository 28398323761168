const EmptyState = (props) => {
    const {reset, isLoading} = props
    return (
        <>
            {
                isLoading ? <div className="my-5">Loading...</div> :
                <div className="my-5">No Results Found.  <u className="pointer" onClick={reset}>Click here</u> to reset filters. </div>
            }
        </>
    )
}

export default EmptyState;