import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPasswordDetails,
  setAuthenticated,
} from "../redux/slice/forgotPasswordSlice";
import { useNavigate } from "react-router-dom";

const ForgotPWDeatils = () => {
  const [showError, setShowError] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    username: "",
    otp: "",
    new_pwd: "",
    confirm_new_pwd: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let errorFromRedux = useSelector(
    (state) => state?.forgotPasswordSlice?.errorMessage?.message
  );
  const success = useSelector((state) => state.forgotPasswordSlice.success);



  const [credentials, setCredentials] = useState({
    username: "",
    otp: "",
    new_pwd: "",
    confirm_new_pwd: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });

    if (name === "new_pwd" || name === "confirm_new_pwd") {
      if (name === "new_pwd" && credentials.confirm_new_pwd !== value) {
        setPasswordError("Passwords do not match");
      } else if (name === "confirm_new_pwd" && credentials.new_pwd !== value) {
        setPasswordError("Passwords do not match");
      } else {
        setPasswordError(null);
      }
    }
  };

  const validateForm = () => {
    const errors = {
      username: "",
      otp: "",
      new_pwd: "",
      confirm_new_pwd: "",
    };

    if (!credentials.username) {
      errors.username = "Username is required";
    }
    if (!credentials.otp) {
      errors.otp = "OTP is required";
    }
    if (!credentials.new_pwd) {
      errors.new_pwd = "New Password is required";
    }
    if (!credentials.confirm_new_pwd) {
      errors.confirm_new_pwd = "Confirm Password is required";
    }
    if (credentials.new_pwd !== credentials.confirm_new_pwd) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError(null);
    }

    setFormErrors(errors);
    return (
      !errors.username &&
      !errors.otp &&
      !errors.new_pwd &&
      !errors.confirm_new_pwd &&
      !passwordError
    );
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setShowError(false);

    if (validateForm()) {
      setLoading(true);
      try {
        await dispatch(forgotPasswordDetails(credentials));
        // await dispatch(changeFlag()
      } catch (error) {
        setShowError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleNavigate = () => {
    dispatch(setAuthenticated(true));
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-8">
            <div className="card ">
              <div className="card-body">
                <h4 className="card-title text-center">Reset Password</h4>
                <form onSubmit={formSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="userName" className="form-label">
                          Username:<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formErrors.username ? "is-invalid" : ""
                          }`}
                          id="userName"
                          name="username"
                          required
                          value={credentials.username}
                          onChange={handleInputChange}
                          placeholder="Enter Username"
                        />
                        {formErrors.username && (
                          <div className="invalid-feedback">
                            {formErrors.username}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="otp" className="form-label">
                          OTP:<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formErrors.otp ? "is-invalid" : ""
                          }`}
                          id="otp"
                          name="otp"
                          required
                          value={credentials.otp}
                          onChange={handleInputChange}
                          placeholder="Enter OTP"
                        />
                        {formErrors.otp && (
                          <div className="invalid-feedback">
                            {formErrors.otp}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="newPassword" className="form-label">
                          New Password:<span className="text-danger">*</span>
                        </label>
                        <input
                          type="password"
                          className={`form-control ${
                            formErrors.new_pwd ? "is-invalid" : ""
                          }`}
                          id="newPassword"
                          name="new_pwd"
                          value={credentials.new_pwd}
                          onChange={handleInputChange}
                          placeholder="Enter New Password"
                        />
                        {formErrors.new_pwd && (
                          <div className="invalid-feedback">
                            {formErrors.new_pwd}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password:
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="password"
                          className={`form-control ${
                            formErrors.confirm_new_pwd ? "is-invalid" : ""
                          }`}
                          id="confirmPassword"
                          name="confirm_new_pwd"
                          value={credentials.confirm_new_pwd}
                          onChange={handleInputChange}
                          placeholder="Confirm Password"
                        />
                        {formErrors.confirm_new_pwd && (
                          <div className="invalid-feedback">
                            {formErrors.confirm_new_pwd}
                          </div>
                        )}
                        {passwordError && (
                          <div className="text-danger small">
                            {passwordError}
                          </div>
                        )}

                         {errorFromRedux && (
                          <div className="text-danger small">
                            {errorFromRedux?.error}
                          </div>
                        )} 
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 d-flex gap-4 justify-content-end">
                      <button
                        className="btn btn-outline-danger"
                        onClick={handleNavigate}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-success"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPWDeatils;
