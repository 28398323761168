
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from '../../apiAccess';

// Create an async action to submit the username
export const submitUsernameAsync = createAsyncThunk(
  'forgotPassword/submitUsername',
  async (username, thunkAPI) => {
    try {
      const response = await axiosClient.post('/api/forgot_pwd/', { username });
      if (response.data) {
        return response.data; 
      } else {
        return thunkAPI.rejectWithValue('Username submission failed.');
      }
    }  catch (error) {
      return thunkAPI.rejectWithValue({ message: error.response.data.error });
    }
  }
);
export const forgotPasswordDetails = createAsyncThunk(
  'forgotPassword/submitUsernameFinal',
  async (username, thunkAPI,) => {
    const passData = JSON.stringify(username);
    try {
      const response = await axiosClient.put('/api/forgot_pwd/', passData);
      if (response.data) {
        return response?.data; 
        
      } else {
        return thunkAPI.rejectWithValue('Username submission failed.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.response.data });
    }
  }
);



const initialState = {
  username: null,
  status: 'idle',
  error: null,
  errorMessage: null,
  success: null,
  isAuthenticated: true,
  errorWhilePasswordDetails: false,

};


const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    resetState: (state) => {
      return initialState; 
    },
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitUsernameAsync.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(submitUsernameAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.username = action.payload;
        state.isAuthenticated = false;
        state.error = null;
      })
      .addCase(forgotPasswordDetails.fulfilled, (state, action) => {
        return {
          ...state,
          success: action.payload,
          errorWhilePasswordDetails: true,
          error: null,
        };
      })
      
      .addCase(forgotPasswordDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
        state.errorWhilePasswordDetails = false
      })
      .addCase(submitUsernameAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setUsername ,setAuthenticated,resetState,changeFlag} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
