// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#summary_table tbody tr:last-child:not(:only-child) {
    font-weight: 600;
}
#summary_table td.sticky-col:not(:nth-of-type(2n-1)) {
    background-color: #FFF;
}

#summary_table th.sticky-col, #summary_table td.sticky-col {
    position: sticky;
    left: 0;
}

.custom-table-container {
    max-width: 100%;
    max-height: 75vh;
    overflow-x: auto;
}
.custom-table-container::-webkit-scrollbar {
    width: 4px; /* Adjust the width as needed */
    height: 4px;
    background-color: #EEE;
}

/* Style the scrollbar thumb (the draggable part) */
.custom-table-container::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 4px; /* Creates rounded corners for the thumb */
}

/* Style the scrollbar thumb on hover */
.custom-table-container::-webkit-scrollbar-thumb:hover {
    background-color: darkergreen;
    cursor: pointer;
}

#summary_table thead {
    position: sticky;
    top: 0;
    z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/summary_data/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,OAAO;AACX;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,UAAU,EAAE,+BAA+B;IAC3C,WAAW;IACX,sBAAsB;AAC1B;;AAEA,mDAAmD;AACnD;IACI,sCAAsC;IACtC,kBAAkB,EAAE,0CAA0C;AAClE;;AAEA,uCAAuC;AACvC;IACI,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,YAAY;AAChB","sourcesContent":["#summary_table tbody tr:last-child:not(:only-child) {\n    font-weight: 600;\n}\n#summary_table td.sticky-col:not(:nth-of-type(2n-1)) {\n    background-color: #FFF;\n}\n\n#summary_table th.sticky-col, #summary_table td.sticky-col {\n    position: sticky;\n    left: 0;\n}\n\n.custom-table-container {\n    max-width: 100%;\n    max-height: 75vh;\n    overflow-x: auto;\n}\n.custom-table-container::-webkit-scrollbar {\n    width: 4px; /* Adjust the width as needed */\n    height: 4px;\n    background-color: #EEE;\n}\n\n/* Style the scrollbar thumb (the draggable part) */\n.custom-table-container::-webkit-scrollbar-thumb {\n    background-color: var(--primary-color);\n    border-radius: 4px; /* Creates rounded corners for the thumb */\n}\n\n/* Style the scrollbar thumb on hover */\n.custom-table-container::-webkit-scrollbar-thumb:hover {\n    background-color: darkergreen;\n    cursor: pointer;\n}\n\n#summary_table thead {\n    position: sticky;\n    top: 0;\n    z-index: 100;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
