const formatText = row => {
    if (!row || !row.value || typeof row.value !== 'string') return row;
    return row.value.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const columns = [
    {
        Header: 'District',
        accessor: 'district_name',
        width: '120px',
        Cell: formatText
    },
    {
        Header: 'Block',
        accessor: 'block_name',
        width: '120px',
        Cell: formatText
    },
    {
        Header: 'Village',
        accessor: 'village_name',
        // width: '120px',
        Cell: formatText
    },
    {
        Header: 'Farmers Registered',
        accessor: 'total_farmers_registered',
    },
    {
        Header: 'Land Registered',
        accessor: 'total_land_recorded',
    },
    {
        Header: 'Baler',
        columns: [
            {
                Header: 'Farmers with Confirmed Bookings',
                accessor: 'b_farmers_with_confirmed_bookings'
            },
            {
                Header: 'Land with Confirmed Bookings',
                accessor: 'b_confirmed_bookings_count'
            },
            {
                Header: 'Farmers with Completed Bookings',
                accessor: 'b_farmers_with_completed_bookings'
            },
            {
                Header: 'Land Cleared',
                accessor: 'b_completed_bookings_count'
            },
        ]
    },
    {
        Header: 'Super Seeder',
        columns: [
            {
                Header: 'Farmers with Confirmed Bookings',
                accessor: 's_farmers_with_confirmed_bookings'
            },
            {
                Header: 'Land with Confirmed Bookings',
                accessor: 's_confirmed_bookings_count'
            },
            {
                Header: 'Farmers with Completed Bookings',
                accessor: 's_farmers_with_completed_bookings'
            },
            {
                Header: 'Land Cleared',
                accessor: 's_completed_bookings_count'
            },
        ]
    },
]