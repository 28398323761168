import React from 'react';
import { useTable } from 'react-table'
import "./table.css";
import { CustomTableEmptyState } from './CustomTableEmptyState';

function CustomTable(props) {
    const {columns, data, id, isLoading, reset} = props;
    const columnCount = () => {
        let count = 0;
         if (columns && columns.length) columns.forEach(c => {
            if (c.columns) count = count + c.columns.length;
            count = count + 1;
        });
        return count;
    }
    
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});

    return (
        <table id={id} className="table react-table mb-0" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((col,i) => (
                            <th className={i === 0 ? 'sticky-col' : ''} {...col.getHeaderProps()}>
                                {col.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {!isLoading && rows && rows.length ? 
                    rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, i) => (
                                    <td className={i === 0 ? 'sticky-col' : ''} {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        )}
                    ) : isLoading ? 
                    <tr><td className='is-loading' colSpan={columnCount()}>Loading...</td></tr> :
                    <CustomTableEmptyState columnCount={columnCount()} reset={reset}/>
                }
            </tbody>
        </table>
    )
}
export {CustomTable};