import React from "react";
import { Navbar } from "../components";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import "../EventPages/eventStyle.css";
import EventForm from "./EventForm";
import { useDispatch, useSelector } from "react-redux";
const CreateEvent = (props) => {


  return (
    <>
      <div className="container mx-4 mt-2 pt-3">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-6 d-flex justify-content-between align-items-center">
            <div>
              <h5
                onClick={() => {
                  props.toggleComponents(false);
                }}
              >
                <BiArrowBack
                  style={{ marginRight: "8px", cursor: "pointer" }}
                />
                Create Event
              </h5>
            </div>
          </div>
        </div>
      </div>
      <EventForm toggleComponents={props.toggleComponents}/>
    </>
  );
};

export default CreateEvent;
