import { useEffect, useRef, useState } from "react";

export {NumInput};

function NumInput(props) {
    const {label, data, name, bubble, readOnly, layout} = props;
    const inputRef = useRef();

    let computedValue = data && data[name] ? data[name] : '';

    const handleInputChange = (e) => {
        const {value, name} = e.target;
        bubble(name, value);
    }
    useEffect(() => {
        // disables scroll to change input type number
        const cancelMouseWheel = e => e.preventDefault();
        const inputElement = inputRef.current;
        inputElement.addEventListener('mousewheel', cancelMouseWheel, { passive: false });
        return () => inputElement.removeEventListener('mousewheel', cancelMouseWheel);
    }, []);

    return (
        <div className={`mb-3 ${layout || ''}`}>
            <label className="form-label">{label}</label>
            <input
                type="number"
                name={name}
                min="0"
                inputMode="decimal" /* this will show the correct keyboard on mobile devices */
                placeholder="0"
                className={`form-control ${readOnly ? 'form-control-plaintext' : ''}`}
                value={computedValue}
                onChange={handleInputChange}
                pattern="^\d*(\.\d{0,2})?$"
                readOnly={readOnly}
                ref={inputRef}
            >
            </input>
        </div>
    );
}