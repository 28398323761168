export const isFormValid = (formValues) => {
  const errors = {};

  const {
    event_name,
    event_date,
    event_time,
    event_description,
    type_of_participants,
    total_no_of_participants,
    event_type,
    district_name,
    block_name,
    village_name,
  } = formValues;

  if (!event_name || event_name.trim() === "") {
    errors.event_name = "Event Name is required.";
  }

  if (!event_date) {
    errors.event_date = "Event event date is required.";
  }

  if (!event_time) {
    errors.event_time = "Start Time is required.";
  }

  if (!event_description || event_description.trim() === "") {
    errors.event_description = "Event description is required.";
  } else if (event_description.length < 50) {
    errors.event_description = "Description should be at least 50 characters.";
  } else if (event_description.length > 140) {
    errors.event_description = "Description should be 140 characters or less.";
  }

  if (!type_of_participants) {
    errors.type_of_participants = "Type of Participants is required.";
  }

  if (!total_no_of_participants || isNaN(total_no_of_participants)) {
    errors.total_no_of_participants =
      "Total Number Participants is required and should be a number.";
  }

  if (!event_type) {
    errors.event_type = "Type of Events is required.";
  } else if (event_type === "district") {
    if (!district_name) {
      errors.district_name = "District is required.";
    }
   }
   else if (event_type === "block") {
    if (!district_name) {
      errors.district_name = "District is required.";
    }
    if (!block_name) {
      errors.block_name = "Block is required.";
    }
  } else if (event_type === "village") {
    if (!district_name) {
      errors.district_name = "District is required.";
    }
    if (!block_name) {
      errors.block_name = "Block is required.";
    }
    if (!village_name) {
      errors.village_name = "Village is required.";
    }
  }



  return errors;
}
