import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../components';

const PageNotFound =()=> {
    const navigate = useNavigate();
    return (
        <>  
            <Navbar />
            <div className="container pt-5">
                <h3>The page you’re looking for doesn’t exist :(</h3>
                <button className="btn btn-outline-success mt-4" onClick={() => navigate('/login')} >Go Back</button>
            </div>
        </>
    )
        
}
export default PageNotFound;