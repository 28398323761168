import { useEffect, useState } from 'react';
import { NumInput } from '../components';

export { Utilisation };

function Utilisation(props) {
    const [values, setValues] = useState(props.data)
    const bubble = (key, val) => {
        props.callback('utilization_in_industries', {...values, [key]: val});
    };

    useEffect(() => {
        setValues(props.data);
    }, [props.data]);
    
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#utilization_in_industries">
                    Utilisation in Industries
                </button>
            </h2>
            <div id="utilization_in_industries" className="accordion-collapse collapse section" data-bs-parent="#summaryForm">
                <div className="accordion-body">
                    <h6>Tentative straw generation (in tonnes)</h6>
                    <div className="row">
                        <NumInput bubble={bubble} name="tentative_straw_generation_tonnes" label="Tentative straw generation" layout="col-lg-4 col-md-6" {...props}></NumInput>
                    </div>
                    <h6>Nos. of industries in the vicinity of the district utilizing paddy straw (details of industries is to be provided seprately)</h6>
                    <div className="row">
                        <NumInput bubble={bubble} name="industries_vicinity_district_utilizing_paddy_straw_1" label="A" layout="col-lg-4 col-md-6" {...props}></NumInput>
                        <NumInput bubble={bubble} name="industries_vicinity_district_utilizing_paddy_straw_2" label="B" layout="col-lg-4 col-md-6" {...props}></NumInput>
                    </div>
                    <h6>Paddy straw procured by industries (in tonnes)</h6>
                    <div className="row">
                        <NumInput bubble={bubble} name="paddy_straw_produced_by_industries_tonnes" label="Paddy straw procured by industries" layout="col-lg-4 col-md-6" {...props}></NumInput>
                    </div>
                </div>
            </div>
        </div>
    )
}