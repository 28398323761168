import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./accounts/Login";
import Home from "./pages/Home";
import Update from "./pages/Update";
import Marketplace from "./pages/Marketplace";
import Database from "./pages/Database";
import ProtectedRoute from "./routes/ProtectedRoute";
import PageNotFound from "./routes/PageNotFound";
import ChangePasswordPage from "./pages/ChangePassword";
import ToggleComponent from "./EventPages/ToggleComponent";
import ForgotPassword from "./accounts/ForgotPassword";
import Activities from "./pages/Activities";
import VillageActivities from "./pages/VillageActivities";

const App = () => {
  return (
    <div>
    

      <Routes>
        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/update" element={<Update />} />
          <Route path="/database" element={<Database />} />
        <Route path="/change_password" element={<ChangePasswordPage />} />
        </Route>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/activities/villages" element={<VillageActivities />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/eventlandingpage" element={<ToggleComponent />} />
        <Route path="/forgotPassword" element={<ForgotPassword/>} /> 
        {/* Not finding any routes   */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default App;
