import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import "./eventStyle.css";
import { TimeFormat } from "./TimeFormat";
import { FcNext, FcPrevious } from "react-icons/fc";
import { baseURL } from "../apiAccess";

const EventDetails = (props) => {
  const [singleEvent, setSingleEvent] = useState(null);

  useEffect(() => {
    setSingleEvent(props?.event);
  }, [props.event]);

  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === singleEvent.files.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? singleEvent.files.length - 1 : slide - 1);
  };

  const getEventParticipantType = (type) => {
    if (type === "farmer") {
      return "Farmer";
    } else if (type === "chc") {
      return "CHC";
    } else {
      return "Farmer and CHC";
    }
  };

  const getEventType = (type) => {
    if (type === "block") {
      return "Block Level";
    } else if (type === "village") {
      return "Village Level";
    } else {
      return "District Level";
    }
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <div className="carousel">
              <FcPrevious onClick={prevSlide} className="arrow arrow-left" />
              {singleEvent &&
                singleEvent?.files.map((item, idx) => {
                  return (
                    <img
                      src={`${baseURL}${item.event_file}`}
                      alt={item.alt}
                      key={idx}
                      className={slide === idx ? "slide" : "slide slide-hidden"}
                    />
                  );
                })}
              <FcNext onClick={nextSlide} className="arrow arrow-right" />
              <span className="indicators">
                {singleEvent &&
                  singleEvent?.files.map((_, idx) => {
                    return (
                      <button
                        key={idx}
                        className={
                          slide === idx
                            ? "indicator"
                            : "indicator indicator-inactive"
                        }
                        onClick={() => setSlide(idx)}
                      ></button>
                    );
                  })}
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-11 mx-4">
            <h3>{singleEvent?.event_name}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-11 mx-4">
            <p>{singleEvent?.event_description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="row">
              <div className="col-md-2">
                <ul className="list_of_veiw">
                  <li>Date and Time:</li>
                  <li>Venue:</li>
                  <li>Type of Participants:</li>
                  <li>No of Participants:</li>
                  <li>Type of Event:</li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="list_of_veiw2">
                  <li>
                    {singleEvent?.event_date
                      ? format(new Date(singleEvent?.event_date), "MM/dd/yyyy")
                      : ""}{" "}
                    at {TimeFormat(singleEvent?.event_time)}
                  </li>
                  <li>
                    {singleEvent && (
                      <span>
                        <span>
                          {singleEvent.district_name &&
                            `${singleEvent.district_name}`}{" "}
                          {singleEvent.block_name &&
                            `, ${singleEvent.block_name}`}
                          {singleEvent.village_name &&
                            `, ${singleEvent.village_name}`}
                        </span>
                      </span>
                    )}
                  </li>
                  <li>
                    {getEventParticipantType(singleEvent?.type_of_participants)}
                  </li>
                  <li>{singleEvent?.total_no_of_participants}</li>
                  <li>{getEventType(singleEvent?.event_type)}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetails;
